.searchable-users-filter-wrapper {
    min-width: 260px;
    height: 300px;
    overflow: hidden;
    border-radius: 2px;
    background:  #FFF;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

}

.searchable-users-filter-data.searchable-users-filter-wrapper > .separator {
    height: 1px;
    background: #F1F1F1;
}

.searchable-users-filter-data.searchable-users-filter-wrapper .user-item:hover {
    background: #F5F5F5;
}

.searchable-users-filter-data.searchable-users-filter-wrapper .user-item .name {
    padding-right: 8px;
    margin-left: 4px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 205px;
}

.searchable-users-filter-wrapper .dropdown {
    overflow: hidden;
    overflow-y: auto;
    height: 259px;
    padding: 5px 0 5px 0;
    margin-top: 5px;
}

.searchable-users-filter-wrapper .dropdown > div {
    height: 32px;
    display: flex;
    align-items: center;
}

.searchable-users-filter-wrapper .dropdown > div > span {
    margin-left: 8px;
}


.searchable-users-filter-wrapper .dropdown > div > label {
    margin-left: 12px;
}

.searchable-users-filter-search {
    padding: 12px 10px 0 10px;
}

.searchable-users-filter-search input, .searchable-users-filter-search button {
    height: 24px;
}

.searchable-users-filter-search button {
    width: 24px;
}

.no-users {
    height: 259px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    flex-direction: column;
}

.no-users > div:first-child {
    margin-bottom: 8px;
}