.documents-page-link-modal-window .documents-page-modal-input-item > div:first-child {
    line-height: 22px;
}

.documents-page-link-modal-window .documents-page-modal-input-title {
    margin-bottom: 8px;
}

.documents-page-link-modal-window .documents-page-modal-text-area-title {
    margin-bottom: 8px;
}

.documents-page-link-modal-window .width-90 {
    width: 90px;
}
