.project-summary {
    margin: 0 16px 16px 24px;
    height: calc(100% - 80px);
    overflow: auto;
}

.project-summary h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin: 0 0 10px;
}

.project-summary .form-header {
    display: flex;
    justify-content: space-between;
}

.project-summary .project-summary-view > div {
    position: relative;
    padding-right: 16px;
}

.project-summary .project-view-edit-form {
    width: 400px;
}
.project-summary .separator {
    width: 1px;
    display: block;
    background-color: #F5F5F5;
    height: calc(100vh - 152px);
    top: -48px;
    overflow: hidden;
    position: absolute;
}

.project-summary .project-view-edit-form .ant-form-item-label > label  {
    color: #000;
    font-weight: 600;
}
.project-summary .project-view-edit-form .form-item,
.project-summary .form-info .form-item
{
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 16px 0 0 0;
}

.project-summary .project-view-edit-form .form-item p {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.project-summary .form-header .project-name {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    cursor: pointer;
    width: fit-content;
}

.project-summary .form-header .project-name > .edit-icons {
    display: flex;
    align-items: center;
}
.project-summary .project-view-edit-form .description-container .subtitle .edit-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.project-summary .form-header .project-name > .edit-icons > .check-outlined,
.project-summary .project-view-edit-form .description-container .subtitle > .edit-icons > .check-outlined {
    color: #52C41A;
    margin: 0 16px 0 14px;
}
.project-summary .form-header  .project-name > .edit-icons > span > svg,
.project-summary .form-header  .project-name > .edit-icons > .check-outlined > span > svg,
.project-summary .project-view-edit-form  .description-container .subtitle > .edit-icons > .check-outlined > span > svg,
.project-summary .project-view-edit-form .description-container .subtitle > .edit-icons > span > svg {
    width: 16px;
    height: 16px;
}

.project-summary .form-header .project-name > input {
    padding: 5px 12px;
    min-width: 308px;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
}

.project-summary .form-header .project-name > span > input {
    width: 308px;
}

.project-summary .form-header .project-name > textarea {
    resize: none;
    border: none;
    padding: 5px 12px;
}

.project-summary .form-header .project-name > p {
    margin: 0;
    max-width: 380px;
}

.project-summary .form-header .project-name > span > textarea {
    width: 340px;
}
.project-summary .project-view-edit-form .description-container .subtitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.project-summary .form-header .project-name > span > svg,
.project-summary .project-view-edit-form .description-container .subtitle > span > svg {
    display: none;
    width: 16px;
    height: 16px;
}
.project-summary .form-header .project-name > span > svg {
    margin-left: 14px;
}
.project-summary .project-view-edit-form .description-container .subtitle > span > svg {
    margin-left: 12px;
}

.project-summary .form-header .project-name:hover > span > svg,
.project-summary .project-view-edit-form .description-container:hover .subtitle > span > svg {
    display: inline-block;
}

.project-summary .project-view-edit-form .stats-container {
    margin: 0;
    align-items: center;
}

.project-summary .project-view-edit-form .description-container
{
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    cursor: pointer;
}
.project-summary .project-view-edit-form .active {
    width: 500px;
}

.project-summary .project-view-edit-form .description-container .edit-icons-description {
    width: 100%;
}

.project-summary .project-view-edit-form .description-container .project-description {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    white-space: break-spaces;
}

.project-summary .project-view-edit-form .description-container > .text-area-container  {
    display: flex;
    border: none;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 0;
    min-width: 500px;
}

.project-summary .project-view-edit-form .description-container > .description-icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.project-summary .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
    padding: 0 11px;
}

.project-summary .lead-item {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.project-summary .lead-item .profile-pic {
    margin-right: 4px;
}

.project-summary .project-summary-view {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
}

.project-summary .project-summary-right-column {
    margin-top: -48px;
    padding: 16px 0 16px 16px;

}

.project-summary .project-summary-right-column .title {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    margin-bottom: 18px;
    width: 333px;
}

.project-summary .project-summary-right-column .project-summary-status {
    display: flex;
}

.project-summary .project-summary-right-column .project-summary-status-banner {
    border-radius: 8px;
    border: 1px solid #B7EB8F;
    background: #F6FFED;
    height: 154px;
    padding: 16px;
    width: 333px
}

.project-summary .project-summary-right-column .project-summary-status-banner.requested {
    height: 154px;
}


.project-summary .project-summary-right-column .project-summary-status-banner .request-message {
    color: #000;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 16px;
    width: 299px;
}

.project-summary .project-summary-right-column .project-summary-status-banner .buttons {
    display: flex;
    margin-top: 16px;
}

.project-summary .project-summary-right-column .project-summary-status-banner .buttons > div {
    border-radius: 2px;
    background: #FFF;
    padding-left: 8px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-right: 8px;
    cursor: pointer;
}

.project-summary .project-summary-right-column .project-summary-status-banner .buttons .selected {
    border: 1px solid #1890FF!important;
}

.project-summary .project-summary-right-column .project-summary-status-banner .buttons > div:first-child {
    border: 1px solid #D9D9D9;
    color: #262626;
    width: 132px;
    height: 24px;
}

.project-summary .project-summary-right-column .project-summary-status-banner .buttons > div > label {
    margin-left: 5px;
    cursor: pointer;
    white-space: nowrap;
}

.project-summary .project-summary-right-column .project-summary-status-banner .buttons > div:last-child {
    border: 1px solid #FF4D4F;
    color: #FF4D4F;
    width: 158px;
    height: 24px;
}

.project-summary .project-summary-right-column .project-summary-status-banner .buttons.request > div:first-child {
    width: 175px;
}

.project-summary .project-summary-right-column .project-summary-status-banner .buttons.request > div:last-child {
    width: 87px;
}

.project-summary .project-summary-right-column .project-summary-status > div:first-child {
    color: #000;
    width: 100px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.project-summary .form-info > div {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}

.project-summary .form-info > div:last-child {
    color: #1890FF;
    margin-top: 8px;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
}

.project-summary .response-item {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    margin-bottom: 16px;
}

.project-summary-response .response-item {
    margin-bottom: 16px;
}

.project-summary-response .response-item > div:first-child {
    font-weight: 600;
    margin-bottom: 8px;
    color: #000000;
}

.project-summary-response .response-item > div:last-child {
    color: #595959;
    font-weight: 400;
    margin-bottom: 8px;
}

.project-summary-response .ant-modal-header {
    height: 38px;
    padding: 0 0 0 24px;
    display: flex;
    align-items: center;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    color: #262626;
}

.project-summary-response .ant-modal-close-x {
    height: 16px;
    width: auto;
    display: inline;
    position: relative;
    top: -10px;
    margin-right: 24px;
}

.project-summary-response .ant-modal-body {
    padding: 16px 24px;
}

.project-summary-response {
    width: 664px!important;
}

.project-summary .label-wrapper {
    display: flex;
}
.project-summary .left-menu {
    display: block;
}

.project-summary .left-menu .date-picker {
    margin-top: 8px;
}
.project-summary .left-menu > span {
    display: flex;
    width: 100px;
    align-items: center;
}
.project-summary .left-menu-wrapper {
    margin-top: 16px;
}

.project-summary .left-menu-wrapper .left-select {
    margin-top: 8px;
}

.project-summary .right-select {
    left: 22px;
}

.project-summary .label-wrapper > span {
    display: flex;
    width: 100px;
    font-weight: 600;
    color: #000000;
    align-items: center;
}
.project-summary .label-wrapper > .type-default {
    color: #000000;
}

.project-summary .template-label {
    background: #9254DE;
    right: 0;
    color: #fff;
    width: 93px;
    height: 28px;
    font-size: 16px;
    text-align: center;
}

.project-summary .client-label {
    color: #1890FF;
    cursor: pointer;
}

.project-summary .project-summary-right-column > div {
    margin-bottom: 16px;
}

.project-summary .project-summary-right-column .ant-select-arrow {
    top: 19px;
}
