.documents-page-table-filter {
    border: 1px dashed #D9D9D9;
    height: 32px;
    width: 90px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 16px 0;
    cursor: not-allowed;
}

.documents-page-table-filter > div {
    line-height: 22px;
    font-weight: 400;
    color: #262626;
}

.documents-page-table-filter > div > .plus {
    margin: 0 4px 0 10px;
}