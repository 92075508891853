.upload-company-logo {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.upload-company-logo .profile-picture {
    margin-bottom: 8px;
}

.upload-company-logo .crop-container {
     display: flex;
     justify-content: center;
}

.upload-company-logo .ant-upload.ant-upload-select-picture-card {
    height: 160px;
    width: 100%;
    padding: 8px;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #F0F0F0;
    background: white;
}
.upload-company-logo .ant-upload.ant-upload-select-picture-card .ant-upload {
    background: #F3F5F6;
    padding: 32px 0;
}

.upload-company-logo .ant-upload.ant-upload-select-picture-card .upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upload-company-logo > .upload-image-active .ant-upload.ant-upload-select-picture-card .ant-upload {
    border: 1px dashed #1890FF;
}

.upload-company-logo .ant-upload.ant-upload-select-picture-card .upload-wrapper .prevImage {
    margin-bottom: 8px;
    width: 75px;
    height: 75px;
}
.upload-company-logo .ant-upload.ant-upload-select-picture-card .upload-wrapper .avatar-initials {
    font-size: 46px;
    margin-bottom: 8px;
    width: 75px;
    height: 75px;
    line-height: 75px;
}

.upload-company-logo .ant-upload.ant-upload-select-picture-card .upload-text {
    color: #1890FF;
}

.upload-company-logo  .ant-upload.ant-upload-select-picture-card .upload-img {
    background: url('../../assets/images/group-img.svg') no-repeat 0 0;
    width: 75px;
    height: 75px;
    display: inline-block;
    margin-bottom: 8px;
}

.upload-company-logo  .delete-button {
    position: absolute;
    z-index: 10;
    top: 12px;
    right: 12px;
    color: #FF4D4F;
    font-size: 16px;
}
.upload-company-logo .slider-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.upload-company-logo .button-container {
    display: flex;
    align-items: center;
    margin-top: 16px;
    align-self: flex-end;
}
.upload-company-logo .button-container > button {
    padding: 1px 8px;
    width: 58px;
    border-radius: 2px;
}
.upload-company-logo .button-container > .cancel-button {
    margin-right: 8px;
}

.upload-company-logo .slider-container .slider-picture {
    margin-right: 8px;
}
.upload-company-logo .slider-container .slider-picture > svg {
    width: 16px;
    height: 16px;
    color: #8C8C8C;
}

.upload-company-logo .slider-container .right-slider-picture {
    margin-left: 8px;
}
.upload-company-logo .slider-container .right-slider-picture > svg {
    width: 24px;
    height: 24px;
    color: #595959;
}

.upload-company-logo .ant-slider {
    width: 100%;
    margin: 0;
}