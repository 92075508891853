.documents-page-operations {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.flex-right {
    display: flex;
    justify-content: right;
    margin-top: 12px;
}

.documents-page-bulk-operations > .document-page-bulk-send-button,
.documents-page-bulk-operations > .document-page-bulk-delete-button {
    margin-right: 8px;
}

.document-page-add-document-menu-item-container {
    display: flex;
    align-items: center;
}
