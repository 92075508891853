.assets-summary {
    width: 100%;
    height: 100%;
    padding: 8px 8px 8px 8px;
}

.assets-summary > div:first-child {
    margin: 0 0 0 24px;
}

.assets-summary > div > h1 {
    margin: 0;
}

.assets-summary .risk-stat {
    margin-top: 20px;
}