.templates-filter > .dropdown {
    padding: 5px 16px;
    border-radius: 2px;
    border: 1px solid  #D9D9D9;
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    margin: -2px 16px 24px 0;
}

.templates-filter > .dropdown > span:first-child {
    margin-right: 6px;
}

.templates-filter > .dropdown:hover,
.templates-filter > .active{
    border-color: #4096ff;
}

.templates-filter > .selected{
    background-color: #bae7ff;
}