.query-export-container {
    float: right;
    margin-right: 40px;
}

.query-export-modal-dialog {
    width: 377px!important;
}

.query-export-modal-dialog .ant-modal-content {
    border-radius: 8px!important;
}

.query-export-modal-dialog .ant-modal-body {
    height: 148px;
    padding: 35px 10px 0 16px;
}

.query-export-modal-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    height: 32px;
    display: inline-flex;
    align-items: center;
}

.query-export-modal-body {
    height: 40px;
    padding: 8px 6px 0 0;
    text-align: center;
}

.query-export-container-btn {
    height: 32px;
    width: 114px;
    border-radius: 2px!important;
    padding: 5px 16px 5px 16px;
    color: #262626!important;
}

.query-export-modal-close-btn,
.query-export-modal-close-btn:hover {
    float: right;
    border: none;
    border-radius: 16px!important;
    color: #595959!important;
}

.query-export-modal-close-btn-icon > svg {
    width: 21px;
    height: 21px;
}
