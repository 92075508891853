.report-preview {
    margin: 21px 16px 24px 24px;
    height: calc(100% - 54px);
    overflow: auto;
}

.report-preview > .tables {
    height: inherit;
    overflow: auto;
}

.report-preview > .report-preview-header {
    display: flex;
    justify-content: space-between;
}

.report-preview > div > .report-preview-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.report-preview > div > .report-preview-buttons .query-export-dropdown {
    margin-left: auto;
}
.query-export-dropdown .search-wrapper-report {
    width: 190px;
    margin: 8px 10px 8px;
}
.report-preview > div > .report-preview-buttons > .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 243px;
}
.report-preview > div > .report-preview-buttons > .dropdown-header > .test-header {
    margin: 0;
}

.report-preview .violetx-export-container-btn {
    height: 32px;
    width: 114px;
    padding: 0;
}

.report-preview .export-container-btn {
    height: 24px;
    width: 98px;
    padding: 0;
}

.report-preview .organization-container-btn{
    height: 32px;
    width: 120px;
    padding: 0;
}
.report-preview .organization-container-btn .selected-organization {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 75px;
}

.report-preview .ant-dropdown-menu-title-content {
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
}

.ant-space .ant-space-horizontal .ant-space-align-center {
    gap: 28px;
}

.report-preview > .report-preview-buttons > button {
    margin: 0 0 18px;
}

.report-preview > div > .empty-list-page > .page-empty > .empty-img {
    background: url("../../assets/images/empty-tables.svg") no-repeat;
    background-size: contain;
    width: 154px;
    height: 128px;
}
.report-preview > div > .empty-list-page > .page-empty > .empty-page-header{
    color: #262626;
    line-height: 32px;
    font-weight: 600;
    font-size: 24px;
}

.report-preview > div > .empty-list-page > .page-empty > .empty-page-text{
    color: #8C8C8C;
}

.report-preview > div > .report-preview-title {
    margin: 0 0 18px 0;
    font-weight: 600;
    color: #000000;
    display: flex;
    align-items: center;
}

.report-preview .ant-table-title {
    color: #1890FF;
    background-color: #F0F0F0;
}

.ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 14px;
    color: #262626;
}