.projects-templates {
    width: 100%;
}

.projects-templates .section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.projects-templates .section-header > div:first-child {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.projects-templates .section-header a {
    color: #1890FF;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

}

.projects-templates .templates-list {
    display: flex;
    padding: 16px 0;
}

.projects-templates .templates-list > div {
    padding: 16px 0;
    width: calc(100% - 44px);
    height: 276px;
}

.projects-templates .templates-list > div:first-child,
.projects-templates .templates-list > div:last-child {
    display: flex;
    width: 28px;
    align-items: center;
    justify-content: center;
    height: 292px;
    background: #FFFFFF;
    padding: 10px 16px 10px 2px;
}

.projects-templates .templates-list > div:last-child {
    padding: 10px 16px 10px 16px;
}

.projects-templates .carousel-wrapper {
    height: 260px;
    overflow: hidden;
    background: #FFFFFF;
}

.projects-templates .carousel {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
}