.scans-page {
    height: 100%;
}

.scans-page .scans-page-header {
    padding: 0 0 16px;
    margin-left: 16px;
    border-bottom: 1px solid #f0f0f0;
    height: 60px;
    display: flex;
    align-items: center;
}
.scans-page .scans-page-header > .arrow {
    margin: 0 15px 0 3px;
    cursor: pointer;
    height: 17px;
    width: 17px;
    font-size: 17px;
    color: #262626;
}
.scans-page .scans-page-header > .header-image {
    width: 32px;
    height: 32px;
    border-radius: 1.25px;
}
.scans-page .scans-page-header > h3 {
    color: #2E2C34;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0 13px 0 8px;
}
.scans-page .scans-page-header > p {
    color: #8C8C8C;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
}
.scans-page .scans-page-header h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin: 0;
    padding-left: 24px;
}

.scans-page .scans-menu {
    margin-left: 16px;
}

.scans-page .height {
    height: calc(100% - 64px);
}