.query-editor {
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
}

.query-editor-page .ant-tabs-nav {
    margin-bottom: 0;
}

.query-editor .editor-gutter {
    background: #FFFFFF!important;
    border-bottom: 1px solid #EDEEEF;
}

.query-editor .editor-dragger {
    height: 6px!important;
    width: 23px!important;
    background-image: url(../../assets/images/dragger.svg) !important;
    background-color: #FFFFFF!important;
}

.query-editor-page .ant-tabs, .query-editor-page .ant-tabs-tabpane, .query-editor-page .ant-tabs-content {
    height: 100%!important;
    width: 100%;
}

.query-editor .editor-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.query-editor .editor-container .command-buttons {
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
}

.query-editor .editor-container .command-buttons .command-button {
    width: 80px;
    height: 24px;
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.query-editor .editor-container > div:last-child {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: calc(100% - 40px);
}

.query-editor .editor-text {
    border: 0;
    width: calc(100% - 255px);
    height: 100%;
    resize: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #FAFAFA;
    padding: 10px 18px 10px 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: Roboto Mono, sans-serif;
    line-height: 154%;
}

.query-editor div > .full-width {
    width: 100%;
    background: #FFFFFF;
}

.query-editor .disabled-text-wrapper {
    background: #FAFAFA;
}

.query-editor .disabled-text-wrapper > .warning {
    padding: 11px;
}

.query-editor .disabled-text-wrapper > span {
    font-size: 12px;
    color: #000000;
    line-height: 14px;
}

.query-editor .editor-container > div:last-child > div {
    display: flex;
    height: 100%;
    width: 271px;
    min-width: 271px;
    max-width: 271px;
    overflow: auto;
    flex-direction: column;
    /*padding-top: 22px;*/
    /*padding-left: 16px;*/
}

.query-details-container .editor-container .query-timer > img {
    width: 21px;
    height: 18px;
    margin-left: 17px;
    margin-right: 10px;
}

.query-editor .query-config-container {
    background-color: #FFFFFF;
    overflow-x: hidden;
    overflow-y: auto;
}

.query-editor .timeout-limit > div:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #5B5252;
    margin-bottom: 16px;
}

.query-editor .timeout-limit > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.query-editor .timeout-limit > div:last-child input {
    border: 1px solid var(--border-grey);
    padding-left: 5px;
    height: 26px;
    margin-left: 16px;
    margin-right: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    width: 42px;
    color: #5B5252;
}

.query-editor .timeout-limit > div:last-child .timeout-select > div {
    height: 26px;
    width: 65px;
    display: flex;
    align-items: center;
}

.query-editor .timeout-limit > div:last-child .timeout-select > .ant-select-selection-item{
    line-height: 26px;
}

.query-editor-page .results-container {
    background-color: #FFFFFF;
    height: 100% ;
    overflow: auto;
    padding: 0;
}

.query-editor-page .results-container > div:first-child {
    height: 100%;
    overflow: auto;
}

.__dbk__container {
    display: block!important;
}

.query-editor .editor-container-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
}

.query-config-container .timeout-limit {
    padding: 22px 12px 24px 12px;
}

.query-config-container .query-config {
    border-top: 1px solid #EDEEEF;
}