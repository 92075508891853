.user-menu {
    margin-left: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
}
.user-menu > .user-menu-avatar {
    background-color: #C2E8D9;
    vertical-align: middle;
    color: #4C826B;
}

.user-menu .display-name {
    margin-left: 8px;
    max-width: 105px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-menu:hover {
    color: #40A9FF;
}

.user-menu:active {
    color: #096DD9;
}

.user-menu .caret-icon {
    margin-left: 10px;
}

.user-add-profile-modal .ant-modal {
    display: flex;
    padding: 0;
}
.user-add-profile-modal .ant-modal-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #262626;
}
.user-add-profile-modal .ant-modal-body {
    display: flex;
    padding: 0;
}
.user-add-profile-modal .ant-modal-header {
    padding: 8px 24px;
}

.user-add-profile-modal .profile-picture-container {
    padding: 16px 24px 24px 24px;
}

.user-add-profile-modal .profile-picture-container > .name-group {
    display: flex;
    margin-top: 16px;
}

.user-add-profile-modal .profile-picture-container > .error-description {
    position: relative;
    top: -16px;
}

.user-add-profile-modal .profile-picture-container > .name-group > div > .label,
.user-add-profile-modal .profile-picture-container > .label {
    margin-bottom: 8px;
}
.user-add-profile-modal .profile-picture-container > .label-password {
    margin-bottom: 16px;
}

.user-add-profile-modal .profile-picture-container > .name-group > div > .info {
    margin-bottom: 16px;
    height: 32px;
    width: 205px;
}
.user-add-profile-modal .profile-picture-container > .name-group > div > .first {
    margin-right: 16px;
}
.user-add-profile-modal .profile-picture-container > .info {
    margin-bottom: 16px;
    height: 32px;
    width: 433px;
    padding: 5px 12px;
}
.user-add-profile-modal .profile-picture-container > .button-password {
    padding: 5px 16px;
    height: 32px;
    width: 427px;
}
.user-add-profile-modal .profile-picture-container > .button-password > span > svg {
    font-size: 16px;
}
.user-add-profile-modal .profile-picture-container .ant-modal-close-x {
    height: 38px;
    line-height: 38px;
}
.user-add-profile-modal .profile-picture-container > .upload-company-logo > .crop-container {
    position: relative;
}
.user-add-profile-modal .profile-picture-container > .upload-company-logo .delete-button {
    top: 8px;
    right: 8px;
}

.user-add-profile-modal .profile-picture-container > .upload-company-logo .ant-upload.ant-upload-select-picture-card {
    height: 145px;
}
.user-add-profile-modal .profile-picture-container > .upload-company-logo .ant-upload.ant-upload-select-picture-card .upload-wrapper > .prevImage {
    width: 54px;
    height: 54px;
    border-radius: 4px;
    background: #FFF;
}
.user-add-profile-modal .profile-picture-container > .upload-company-logo .ant-upload.ant-upload-select-picture-card .upload-wrapper .avatar-initials {
    width: 54px;
    height: 54px;
    border-radius: 4px;
    font-size: 20px;
    line-height: 55px;
}

.user-add-profile-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
    height: 38px;
    width: unset;
    margin-right: 24px;
    line-height: 38px;
}
.user-add-profile-modal .ant-modal-footer .ant-btn {
    width: 90px;
    height: 32px;
}

