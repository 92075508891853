.users-summary-container {
    margin-top: 10px;
    padding-left: 32px;
}

.users-summary-container >  .header {
    display: flex;
    align-items: center;
    margin: 20px 32px 0 0;
    justify-content: space-between;
}

.users-summary-container h2 {
    margin: 0;
    color: #000000;
    font-weight: 600;
}

.users-summary-container .command-buttons {
    display: flex;
}

.users-summary-container .command-buttons > button:not(:last-child) {
    margin-right: 8px;
}

.users-summary-container .command-buttons > button:last-child {
    width: 125px;
}

.users-summary-container .devices-list {
    margin-top: 4px;
    margin-right: 4px;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: left;
}

.devices-list .device {
    border: 1px solid #F0F0F0;
    width: 264px;
    height: 204px;
    position: relative;
    display: flex;
    justify-items: center;
    flex-direction: column;
    margin: 20px 20px 0 0;
    padding: 10px;
    border-radius: 8px;
}

.devices-list .device .device-header {
    position: relative;
}

.devices-list .device .device-header .menu {
    cursor: pointer;
}

.devices-list .device .device-icon {
    width: 26px;
    height: 26px;
    margin: 0 8px 0 4px;
    background: #FFFFFF;
    border: 1px solid #EDEEEF;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.devices-list .checkbox-container {
    margin-top: 3px;
}

.devices-list .device-name {
    color: #262626;
    font-weight: 400;
    height: 26px;
    line-height: 26px;
    position: relative;
    top: 2px;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

.devices-list .device .device-icon > img {
    width: 18px;
    height: 18px;
}

.device-info {
    width: 185px;
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.device-version {
    width: 200px;
    height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.devices-list .device > div:first-child {
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: 242px;
    justify-content: space-between;
}

.devices-list .device > div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.devices-list .device .main {
    margin-top: 16px;
}

.devices-list .device > div > div {
    display: flex;
    overflow: hidden;
}

.devices-list .device > .device-header > div {
    height: 29px;
}

.devices-list .device .title {
    min-width: 88px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    height: 29px;
    line-height: 22px;
    letter-spacing: 0.0120588em;
    color: #8C8C8C;
}

.devices-list .device .value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.0120588em;
    color: #262626;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.devices-list .card-setting,
.devices-list .card-setting-open{
    border: 1px solid #D9D9D9;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    width: 232px;
    height: 32px;
}

.devices-list .card-setting:hover:not(.disabled),
.devices-list .card-setting:focus:not(.disabled),
.devices-list .card-setting-open{
    border-color: #1890ff;
}

.devices-list .card-setting:hover:not(.disabled) > span,
.devices-list .card-setting:focus:not(.disabled) > span,
.devices-list .card-setting-open > span{
    color: #1890ff;
}

.devices-list .card-setting.disabled {
    background-color: #F5F5F5;
    color: #BFBFBF;
    cursor: not-allowed;
}

.ant-dropdown a,
.ant-dropdown a:hover{
    color: #1890ff;
}


.device-status {
    position: absolute;
    right: 1px;
    bottom: -5px;
    width: 11px;
    height: 11px;
    border-radius: 6px;
    border: 2px solid #FAFAFA;
}

.status-online {
    background-color: #3DD598;
}

.no-device {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-device > div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.no-device > div span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    display: flex;
    color: #44444F;
    margin-right: 10px;
}

.no-device .disabled > span {
    opacity: 0.25;
}

.no-device .download-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 10px;
    width: 174px!important;
    height: 40px!important;
    margin-top: 71px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #BFBFBF;
}

.no-device .download-button img {
    margin-right: 10px;
}

.devices-list .action-menu > .list-dropdown {
    padding: 4px 10px;
}