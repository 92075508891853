.clients-table {
    height: calc(100% - 85px);
}

.clients-table > div:first-child {
    height: 100%;
}

.clients-table.empty-container > div:first-child {
    height: unset;
}

.clients-table.empty-container > div:nth-child(2) {
    height: 100%;
}

.clients-table.empty-container .ant-table-pagination {
    display: none;
}