.documents-page-header {
    height: 26px;
}
.documents-page-header > .documents-page-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #000000;
}

.documents-page-header > .documents-page-folder-header {
    display: flex;
    justify-content: space-between;
}

.documents-page-header > .documents-page-folder-header > div {
    display: flex;
    align-items: center;
}

.documents-page-header > .documents-page-folder-header > div > span:last-child {
    font-size: 14px;
    line-height: 22px;
}
