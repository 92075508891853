.projects-list-container {
    height: calc(100% - 163px);
    padding: 10px 16px 0 16px;
    width: 100%;
}

.projects-list-container .message-wrapper {
    width: 184px;
    height: 54px;
    margin: 4px 0;
    border-radius: 8px;
    background: #F0F0F0;
    padding: 4px 8px;
}

.projects-list-container .message-wrapper.uber{
    background: #F9F0FF;
}

.projects-list-container .large-row {
    height: 62px;
}

.projects-list-container .message-wrapper > div > span {
    color: #8C8C8C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 127px;
    overflow: hidden;
}

.projects-list-container .message-wrapper > div:first-child{
    display: flex;
    justify-content: space-between;
}

.projects-list-container .message-wrapper > div {
    line-height: 22px;
    height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #262626;
}

.responsible.status-wrapper {
    min-width: 225px;
    width: 100%;
    height: 162px;
    overflow-x: auto;
}

.responsible.status-wrapper > .separator {
    height: 1px;
    background: #F1F1F1;
}

.responsible.status-wrapper .user-item .name {
    padding-right: 8px;
    margin-left: 4px;
}

.projects-list-container .filters-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.status-wrapper {
    width: 185px;
    border-radius: 2px;
    background:  #FFF;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.status-wrapper > div {
    height: 32px;
    display: flex;
    align-items: center;
}

.status-wrapper > div > span {
    margin-left: 8px;
}


.status-wrapper > div > label {
    margin-left: 12px;
}

.projects-list-container h1 {
    width: 72px;
    height: 28px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin: 0;
}

.projects-list-container .search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}

.projects-list-container .search-container .command-buttons > button:first-child {
    position: relative;
    margin-right: 16px;
    height: 32px;
    width: 156px;
}

.projects-list-container .search-container .command-buttons > button:nth-child(2) {
    position: relative;
    margin-right: 16px;
    height: 32px;
    width: 81px;
}

.projects-list-container .table-container {
    margin-top: 8px;
    width: 100%;
    overflow: hidden;
}

.projects-list-container .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 6.5px 8px;
    height: 38px;
}

.projects-list-container .ant-table-thead > tr > th {
    padding: 7px 8px;
    height: 38px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
}

.projects-list-container .ant-table-container {
    border: 1px solid #F0F0F0;
}

.projects-list-container .ant-table-header {
    border-bottom: 1px solid #F0F0F0;
}

.projects-list-container .empty-container > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table-empty > .ant-table-container > .ant-table-body > table > .ant-table-tbody > .ant-table-placeholder {
    display: none;
}

.projects-list-container .ant-table-header .ant-table-cell {
    font-weight: 600;
    font-size: 14px;
    color: #262626;
}

.projects-list-container .ant-table-tbody .table-action img {
    cursor: pointer;
}

.projects-list-container .ant-table-tbody .table-action a {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects-list-container .large-row .ant-table-tbody .table-action a {
    margin-top: -26px;
}

.projects-list-container .ant-dropdown-menu-item a:hover {
    text-decoration: none;
}

.projects-list-container .ant-table-body {
    overflow-y: auto!important;
}

.projects-list-container .search-input:hover:not([disabled]) {
    border-color: #40a9ff;
}

.projects-list-container .search-input {
    border-color: #d9d9d9;
    width: 174px;
}

.projects-list-container .search-input::placeholder {
    color: #8c8c8c;
}

.projects-list-container .ant-dropdown {
    width: 185px;
}

.projects-list-container .search-container > div > .ant-input-search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn:hover > .anticon-search > svg {
    fill: #1890ff;
}

.projects-list-container .search-container > div > .ant-input-search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn > .anticon-search > svg {
    fill: #262626;
}

.projects-list-container .ant-table-thead .ant-table-cell:nth-child(2) {
    padding-left: 10px;
}

.projects-list-container .ant-table-thead .ant-table-cell:nth-child(5),
.projects-list-container .ant-table-tbody .ant-table-cell:nth-child(5) {
    text-align: right;
}

.projects-list-container .ant-table-thead .ant-table-cell:nth-child(6),
.projects-list-container .ant-table-tbody .ant-table-cell:nth-child(6) {
    text-align: right;
}

.projects-list-container .search-container > .command-buttons > button:nth-child(3) {
    width: 32px;
    height: 32px;
    margin-right: 0;
}

.projects-list-container .ant-progress-inner {
    background-color: #D9D9D9;
}

.projects-list-container .ant-progress-success-bg, .ant-progress-bg {
    height: 4px !important;
}

.projects-templates-popup .ant-modal-header .projects-templates-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.projects-templates-popup {
    width: 1082px;
    height: 718px;
}

.projects-templates-popup .ant-modal-header {
    height: 38px;
    padding: 0 24px;
    display: flex;
    margin-left: 4px;
    align-items: center;
}

.projects-templates-popup .ant-modal-header .ant-modal-title {
    width: 100%;
}

.projects-templates-popup .ant-modal-body {
    padding: 16px 27px;
}

.projects-templates-popup .ant-modal-footer {
    height: 52px;
    padding: 0 16px;
}

.projects-templates-popup .projects-templates-footer {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.projects-list-container .task-stat,
.projects-list-container .task-stat span {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
}

.projects-list-container .task-stat span {
    margin-right: 10px;
}

.project-page-tabs .ant-menu-horizontal {
    padding-left: 16px;
}

.project-page-tabs {
    height: 100%;
}

.projects-list-container .search-container input[type="text"], .search-container button {
    height: 32px;
}

.projects-list-container .dropdown {
    padding: 5px 16px;
    border-radius: 2px;
    border: 1px solid  #D9D9D9;
    width: fit-content;
    margin-top: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    margin-right: 16px;
}

.projects-list-container .dropdown > span:first-child {
    padding-right: 8px;
}

.projects-list-container .dropdown:hover,
.projects-list-container .active{
    border-color: #4096ff;
}

.projects-list-container .selected{
    background-color: #bae7ff;
}

.projects-list-container tr {
    cursor: pointer;
}

.projects-list-container .notification-informer {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FF4D4F;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -7px;
}

.projects-list-container .notification-informer-empty {
    background: #FFFFFF;
}