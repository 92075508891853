.clients-groups .ant-menu-inline {
    border-right: none;
}

.clients-groups .groups {
    margin: 13px 16px 13px 8px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    width: 226px;
    display: flex;
    justify-content: space-between;
}

.clients-groups .groups > span {
    font-size: 16px;
}
.clients-groups .search-input {
    margin-left: 8px;
    height: 32px;
    margin-bottom: 8px;
}

.clients-groups .search-input > span:first-child {
    top: 1px;
}

.clients-groups .search-input > span:first-child > input {
    height: 32px;
    width: 203px;
}

.clients-groups .groups-menu > li {
    padding-left: 16px!important;
    background-color: transparent!important;
    margin: 8px 0;
    height: 26px;
}

.clients-groups .groups-menu > li > span:first-child {
    color: #262626;
}
.clients-groups .groups-menu > li > span:nth-child(2) {
    margin-left: 6px;
    padding-left: 4px;
}

.clients-groups .groups-menu > li > span:nth-child(2):hover {
    background-color: #F5F5F5;
}

.clients-groups .groups-menu .ant-menu-item::after{
    display: none!important;
}

.clients-groups .groups-menu > li.ant-menu-item-selected > span:nth-child(2) {
    background-color: #e6f7ff;
}
.clients-groups .query-label {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clients-list-container .clients-groups .ant-menu-item-selected {
    color: #262626;
}

.clients-list-container .clients-groups .groups-menu .ant-menu-item:hover {
    color: #262626;
}

.clients-list-container .clients-groups .query-icons > button.custom-button {
    background-color: inherit;
}