.logged-out {
    width: 100%;
    height: 100%;
    padding: 0;
}

:root {
    --pf-global--danger-color--100: #FB4E4E;
    --pf-global--danger-color--200: #FB4E4E;
}

body {
    background-color: #000000;
}

.logged-out .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logged-out .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
}

.logged-out .first-image {
    background-image: url('../../assets/images/logged-out/LoginBackground1.svg');
    animation: fade-in-out 4s ease-out infinite;
    animation-delay: 0s;
}

.logged-out .second-image {
    background-image: url('../../assets/images/logged-out/LoginBackground2.svg');
    animation: fade-in-out 4s ease-out infinite;
    animation-delay: 2s;
}

.logged-out .third-image {
    background-image: url('../../assets/images/logged-out/LoginBackground3.svg');
    animation: fade-in-out 4s ease-out infinite;
    animation-delay: 4s;
}

.logged-out .forth-image {
    background-image: url('../../assets/images/logged-out/LoginBackground4.svg');
    animation: fade-in-out 4s ease-out infinite;
    animation-delay: 6s;
}

.logged-out .fifth-image {
    background-image: url('../../assets/images/logged-out/LoginBackground5.svg');
    animation: fade-in-out 4s ease-out infinite;
    animation-delay: 8s;
}

.logged-out .six-image {
    background-image: url('../../assets/images/logged-out/LoginBackground1.svg');
    animation: fade-in-out 4s ease-out infinite;
    animation-delay: 12s;
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.logged-out .text-container {
    width: 375px;
    padding: 24px;
    border-radius: 8px;
    position: absolute;
    top: calc(50% - 255.5px);
    left: calc(50% - 187.5px);
    z-index: 1;
    background-color: #262626;
}

.logged-out .text-container .logo {
    text-align: center;
    padding: 32px 16px 32px 16px;
}

.logged-out .text-container .title {
    text-align: center;
    margin: 16px 0;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #FFF;
}

.logged-out .text-container .description {
    margin: 16px 0;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    color: #FFF;
}

.logged-out .text-container .page-image {
    margin: 16px 0;
    width: 100%;
    text-align: center;
}

.logged-out .text-container .sign-in button {
    text-align: center;
    width: 100%;
    height: 40px;
    background-color: #1890FF;
    border-radius: 2px;
    color: #FFF;
    /* Regular/16px | 24px */
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border: 0;
    cursor: pointer;
}