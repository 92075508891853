.date-filter .dropdown {
    padding: 5px 16px;
    border-radius: 2px;
    border: 1px solid  #D9D9D9;
    width: fit-content;
    margin-top: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    margin-right: 16px;
    margin-bottom: 8px;
}

.date-filter .dropdown > span:first-child {
    padding-right: 8px;
}

.date-filter .dropdown:hover,
.date-filter .active{
    border-color: #4096ff;
}

.date-filter .selected{
    background-color: #bae7ff;
}

.date-filter .dropdown .filter-label {
    color: #262626;
}