.documents-page-folder-modal-window .ant-modal-header {
    padding: 8px 50px 8px 24px;
}

.documents-page-folder-modal-window .anticon-close {
    width: 30px;
    height: 30px;
    vertical-align: 6px;
}

.documents-page-folder-modal-window .ant-modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.documents-page-folder-modal-window .ant-modal-body {
    padding-top: 16px;
}

.documents-page-folder-modal-window .documents-page-modal-input-item {
    margin-bottom: 10px;
}

.documents-page-folder-modal-window .documents-page-modal-input-title {
    margin-bottom: 8px;
}

.documents-page-folder-modal-window .documents-page-modal-text-area-title {
    margin-bottom: 8px;
}

.documents-page-folder-modal-window .width-90 {
    width: 90px;
}
