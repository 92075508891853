.user-summary {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 48px;
    color: #262626;
}

.user-summary > h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 30px 0 16px 0;
    color: #000000;
}

.user-summary > .name-group {
    display: flex;
}

.user-summary > .name-group > div > .label {
    margin-bottom: 8px;
}

.user-summary > .name-group > div > .info {
    margin-bottom: 16px;
    margin-right: 16px;
    height: 40px;
    width: 212px;
}

.user-summary > .label {
    margin-bottom: 8px;
}

.user-summary > .info {
    margin-bottom: 16px;
    height: 40px;
    width: 440px;
}

.user-summary > .buttons .ant-radio-button-wrapper {
    margin-bottom: 16px;
    font-size: 16px;
    height: 40px;
}

.user-summary > .buttons .ant-radio-button-wrapper > span {
    top: 4px;
    position: relative;
}

.user-summary > .statistic .ant-statistic-content {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
}

.user-summary > .statistic .ant-statistic-title {
    font-size: 12px;
    color: #8c8c8c;
}

.user-summary > .statistic {
    margin-left: 16px;
}


.user-summary > .error-description {
    display: block;
    position: relative;
    top: -18px;
}

.user-summary .label-wrapper {
    display: flex;
    margin-bottom: 8px;
}

.user-summary .label-wrapper > span {
    margin-left: 6px;
    font-size: 16px;
}

.ant-tooltip-content .title-wrapper > div > span {
    font-weight: 700;
}

.ant-tooltip-content .title-wrapper > .title-block {
    margin-bottom: 10px;
}

.user-summary-invite-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 440px;
}

.user-summary .reset-sent {
    color: #52C41A;
    border-color: #52C41A;
}
