.project-description-page {
    height: 100%;
    overflow: hidden;
}

.project-description-page .project-summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #262626;
    margin: 18px 16px 29px 15px;
}

.project-description-page .project-summary-header div {
    display: flex;
    align-items: center;
}

.project-description-page .project-summary-header .project-name {
    margin-left: 11px;
}

.project-description-page .projects-layout {
    border-top: 1px solid #F5F5F5;
    margin-left: 16px;
}

.project-description-page .projects-layout-menu {
    border-right: 1px solid #F5F5F5;
    margin-top: 4px;
}

.project-description-page .form-header {
    margin-top: 18px;
    width: calc(100% - 16px);
}

.project-description-page .tasks-notifications-informer {
    border-radius: 16px;
    border: 1px solid #FFF;
    background: #F5222D;
    height: 22px;
    min-width: 25px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}

.project-description-page .label-notifications {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}