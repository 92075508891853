.assignees-container {
    display: flex;
    align-items: center;
}

.assignees-container > .assignee {
    height: 30px;
}

.assignees-container > div > .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: unset;
}

.assignees-container > div > .not-assignee {
    width: 24px;
    height: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    color: #1890FF;
    border: 1px solid #1890FF;
}

.assignees-container .assignee {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.assignees-container .assignee > .select-assignee {
    width: 185px;
    color: #000000;
}

.assignees-container .assignee > .select-assignee .close-icon {
    padding-right: 16px;
    color: #262626;
}

.assignees-container .assignee > .assignee-wrapper > .anticon-caret-down {
    display: none;
}

.assignees-container .assignee > .select-assignee + .not-assignee {
    margin-left: 8px;
}

.assignees-container .assignee > .select-assignee:last-child {
    margin-left: 8px;
}

.assignees-container .assignee > .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 3px 11px;
    height: 38px;
}

.assignees-container .assignee > .assignee-wrapper {
    display: flex;
    align-items: center;
}

.assignees-container .assignee > .assignee-wrapper:hover > .ant-tooltip-open + .anticon-caret-down {
    display: block;
}

.assignees-container .assignee > .assignee-wrapper:not(:first-child) > span  {
    margin-left: 8px;
}

.assignees-container .assignee > .assignee-wrapper > .dropdown-icon {
    padding-left: 4px;
}

.assignees-container .created-by {
    margin: 0 16px 0 0;
    max-width: 240px;
    font-weight: 400;
    line-height: 14px;
    color: #000000;
    padding-left: 8px;
}

.assignees-container .tooltip {
    width: 28px;
    height: 28px;
}

.assignee-searchable-select-popup .assignee-options-search {
    margin: 0 8px 10px 8px;
    padding-right: 10px;
}

.assignee-searchable-select-popup .assignee-options-search input {
    height: 24px;
}

.assignee-searchable-select-popup .assignee-options-search button {
    height: 24px;
    width: 24px;
}