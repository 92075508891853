.integrations-config .scans-page-header {
    border-bottom: 0;
}

.integrations-config .list-page {
    padding-top: 0;
}

.integrations-config .scans-page {
    height: calc(100% - 56px);
}

.integrations-config .command-buttons button:last-child {
    margin-left: 8px;
}

.integrations-config .command-buttons .add {
    width: 81px;
}

