.empty-list-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.empty-list-page > .page-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.empty-list-page > .page-empty img {
    width: 158px;
    height: 140px;
}

.empty-list-page > .page-empty > div:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #262626;
    margin-top: 10px;
}

.empty-list-page > .page-empty > div:nth-child(3) {
    height: 58px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8C8C8C;
}

.empty-list-page > .page-empty > div:nth-child(4) {
    margin-top: 4px;
}

.empty-list-page > .page-empty > .add-disabled {
    gap: 8px;
    width: 162px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: center;
}

.empty-list-page .add {
    width: 162px;
    height: 40px;
}

.empty-list-page .add:hover {
    color: #40a9ff;
}

.empty-list-page .add:active {
    border: 1px solid #096dd9;
    color: #096dd9;
}
