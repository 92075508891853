.vulnerabilities {
    margin: 0 16px 0 24px;
}

.vulnerabilities td {
    color: #262626;
}

.vulnerabilities > .inventory-title {
    margin: 24px 0 16px 16px ;
    line-height: 28px;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.vulnerabilities table tr th.ant-table-selection-column,
.vulnerabilities table tr td.ant-table-selection-column {
    padding-right: 0;
    padding-left: 16px;
}

.vulnerabilities .stats-container .ant-statistic-title {
    font-size: 12px;
}

.vulnerabilities > .stats-container {
    margin-bottom: 25px;
}

.vulnerabilities .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 14px;
    color: #262626;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
    height: 0;
}

.ant-tag-orange {
    color: #FA541C;
    background: #FFF2E8;
    border-color: #FFBB96;
}

.ant-tag-red {
    color: #F5222D;
}

.ant-tag-yellow {
    color: #AD8B00;
    background: #FFFB8F;
    border-color: #D4B106;
}

.ant-tag-green {
    color: #52C41A;
}

.vulnerabilities-list .ant-tag-blue {
    color: #8C8C8C;
    background: #F0F0F0;
    border-color: #8C8C8C;
}

.vulnerabilities .controls-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.vulnerabilities .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 6.5px 8px;
    height: 38px;
}

.vulnerabilities .ant-table-thead > tr > th {
    padding: 7px 8px;
    height: 38px;
}


