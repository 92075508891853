.task-comment {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.task-comment .text-area-container {
    width: 100%;
}

.task-comment .comment-text-textarea {
    width: 100%;
}

.task-comment .task-comment-container .avatar-container {
    margin-right: 12px;
}

.task-comment .task-comment-container .text-container .name {
    font-size: 12px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #8C8C8C;
    width: 100%;
}

.task-comment .task-comment-container .text-container .name .comment-date {
    margin-left: 8px;
}

.task-comment .task-comment-container .text-container {
    width: 100%;
}

.task-comment .task-comment-container .text-container .text {
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #262626;
    width: 100%;
    white-space: break-spaces;
    word-wrap: break-word;
    max-width: 350px;
}

.task-comment .command-buttons {
    margin-left: 44px;
    margin-top: 12px;
}

.task-comment .command-buttons span {
    font-size: 12px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #8C8C8C;
    cursor: pointer;
}

.task-comment .command-buttons span:first-child {
    margin-right: 8px;
}

.task-comment .assignees-info {
    margin-right: 12px;
}

.task-comment .assignees-info > span {
    width: 32px !important;
    height: 32px !important;
}

.task-comment .assignees-info > span > span {
    transform: translateX(-50%) translateY(12%) !important;
}
