.scan-summary {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 24px;
    color: #262626;
}

.scan-summary > h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 26px 0 0 0;
    color: #000000;
}

.scan-summary > .label {
    margin-bottom: 8px;
}

.scan-summary > .info {
    margin-bottom: 16px;
    width: 367px;
}

.scan-summary > .buttons {
    margin-bottom: 16px;
}

.scan-summary > .frequency-wrapper {
    display: flex;
    align-items: center;
}

.scan-summary > .frequency-wrapper > .time {
    width: 102px;
    margin: 0 16px;
}

.scan-summary > .frequency-wrapper > button {
    width: 113px;
}
