.monaco-editor {
    --vscode-editorGutter-background: #EBEBEB!important;
    --vscode-editor-background: #FAFAFA!important;
    --vscode-editorLineNumber-activeForeground: #000000!important;
    --vscode-editorLineNumber-foreground: #828282!important;
}

.monaco-editor .view-line {
    font-weight: 500!important;
    font-family: "Roboto Mono", serif !important;
}

.monaco-editor .line-numbers {
    font-weight: 700!important;
    font-family: "Lato", sans-serif!important;
    font-size: 14px!important;
}