.stats-container {
    display: flex;
    margin: 10px 0 0 15px;
}

.stats-container > div {
    margin-right: 40px;
}

.stats-container .ant-statistic-title {
    margin-bottom: -5px;
    margin-top: 4px;
    color: #8c8c8c;
}

.stats-container .ant-statistic-content-value {
    color: #000000;
    font-size: 20px;
}
