.datasets-wrapper > .ant-menu-inline  > .ant-menu-item {
    width: 220px;
}

.datasets-wrapper {
    display: flex;
    margin-left: 16px;
    height: calc(100% - 38px);
    border-top: 1px solid #F5F5F5;
}

.datasets-wrapper .ant-menu {
    height: 100%;
    padding-top: 12px;
    width: 220px;
}