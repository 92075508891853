.result-mapping {
    height: 100%;
    background: #FAFAFA;
    padding: 20px 0 0 12px;
    border-right: 1px solid #EDEEEF;
    width: 263px;
    min-width: 263px;
    max-width: 263px;
}

.result-mapping .result-mapping-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.result-mapping .result-mapping-title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #5B5252;
    margin-bottom: 14px;
}