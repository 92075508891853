.chart-list-container > .view-container {
    list-style: none;
    display: flex;
    margin: 16px 0 20px 0;
    -webkit-justify-content: flex-end;
    justify-content: flex-start;
    align-items: center;
}

.chart-list-container > .view-container > div {
    z-index: 1;
}

.chart-list-container .bar > g > g > rect:first-child {
    fill: #ffffff;
}
.chart-list-container .bar > g > g > rect:nth-child(2) {
    fill: #67CB3466;
    height: 12px;
}

.chart-list-container .chart-name {
    margin: 0;
}

.chart-list-container .chart-table-row > div {
    margin: 0;
    padding: 4px 4px 4px 0;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.chart-list-container .chart-table-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-family: Lato, sans-serif;
    color: #000000;
}

.chart-list-container .chart-table-row:hover {
    background-color: #fafafa;
}
.chart-list-container .chart-name > .chart-icon {
    width: 8px;
    font-size: 8px;
    height: 8px;
    margin-right: 4px;
    color: #262626;
}

.chart-list-container .chart-name > .chart-icon-opened {
    width: 8px;
    font-size: 8px;
    height: 8px;
    margin-right: 8px;
    color: #262626;
}
.chart-list-container .chart-name > .folder-icon {
    margin-right: 4px;
}
.chart-list-container .bar > g > text {
    display: none;
}

.chart-list-container main > div > div:nth-child(2) {
    display: none;
}

.chart-list-container main > div > div > div:nth-child(2) {
    overflow-y: hidden;
    overflow-x: auto;
}

.chart-list-container ._2dZTy:nth-child(even) {
    fill: #fff;
}

.chart-list-container ._9w8d5 {
    fill: #262626;
}

.chart-list-container ._2q1Kt {
    fill: #8c8c8c;
}
.chart-list-container .templates-chart .calendar {
    display: none;
}

.chart-list-container .templates-chart {
    position: relative;
    top: -40px;
}

.chart-list-container  .notification, .chart-list-container  .notification:hover {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FF4D4F;
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 30px;
    z-index: 1;
}