
.tasks-list-container {
    margin: 18px 34px 0 24px;
    height: calc(100% - 85px);
}

.my-tasks {
    margin: 10px 16px 0 16px;
    height: calc(100% - 156px);
}

.tasks-list-container .message-wrapper {
    width: 184px;
    height: 54px;
    margin: 4px 0;
    border-radius: 8px;
    background: #F0F0F0;
    padding: 4px 8px;
}


.tasks-list-container .message-wrapper.uber{
    background: #F9F0FF;
}

.tasks-list-container .large-row {
    height: 62px;
}

.tasks-list-container .message-wrapper > div > span {
    color: #8C8C8C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 127px;
    overflow: hidden;
}

.tasks-list-container .message-wrapper > div:first-child{
    display: flex;
    justify-content: space-between;
}

.tasks-list-container .message-wrapper > div {
    line-height: 22px;
    height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #262626;
}

.tasks-list-container > .table-container {
    display: flex;
    overflow: auto;
}
.tasks-list-container .tasks-list .avatar-assignee:last-child {
    margin-left: 8px;
}

.tasks-list-container > .tasks-option-container > .tasks-title {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    color: #000000;
    font-weight: 600;
}

.my-tasks > .tasks-option-container > .tasks-title {
    font-size: 20px;
}
.tasks-list-container > .tasks-option-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.tasks-list-container > .tasks-option-container .buttons {
    display: flex;
    align-items: center;
}
.tasks-list-container > .tasks-option-container .buttons > label {
    padding: 1px 8px;
    height: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.tasks-list-container > .tasks-option-container .buttons > label > span {
    line-height: 22px;
}

.tasks-list-container .ant-statistic .ant-statistic-title {
    font-size: 12px;
}

.tasks-list-container .ant-table-tbody > tr > :nth-child(4) {
    padding-left: 8px;
}

.tasks-list-container  .ant-table-thead > tr > th {
    padding: 7px 8px;
    height: 38px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    white-space: nowrap;
}
.tasks-list-container .ant-table-body {
    overflow-y: auto!important;
}

.tasks-list-container .ant-table-body:hover{
    cursor: pointer;
}

.tasks-list-container .ant-table-body tr td {
    white-space: nowrap;
}

.tasks-list-container .ant-table-body .lightblue {
    background-color: #E6F7FF;
}

.tasks-list-container > .search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    align-items: center;
    height: unset;
}

.tasks-list-container > .filter {
    border: 1px dashed #D9D9D9;
    height: 32px;
    width: 90px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 16px 0;
    cursor: not-allowed;
}

.tasks-list-container > .filter > div > .plus {
    margin: 0 4px 0 10px;
}

.tasks-list-container > .filter > div {
    line-height: 22px;
    font-weight: 400;
    color: #262626;
}

.tasks-list-container > .empty-container > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    display: none;
}

.add-phase-modal .ant-modal-content,
.add-task-modal .ant-modal-content {
    width: 381px;
    margin: auto;
    border-radius: 4px;
}
.move-phase .ant-modal-content {
    width: 383px;
    height: 170px;
    margin: auto;
    border-radius: 4px;
}
.move-phase .ant-modal-content > button > .ant-modal-close-x {
    line-height: 42px;
}
.move-phase .ant-modal-body {
    padding: 0
}
.move-phase .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-body > .ant-modal-confirm-title {
    height: 38px;
    padding: 8px 24px;
    border-bottom: 1px solid #F0F0F0;
    font-size: 14px;
    line-height: 22px;
}
.move-phase .ant-modal-body > .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    margin-top: 18px;
    padding: 0 16px 10px 0;
}

.move-phase .ant-modal-body > .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns > button {
    width: 90px;
}

.ant-modal-confirm-content > form > .ant-form-item {
    margin-bottom: 18px;
    padding: 0 16px;
}
.add-phase-modal .ant-modal-title,
.add-task-modal .ant-modal-title {
    font-size: 14px;
}

.add-phase-modal .ant-modal-header,
.add-task-modal .ant-modal-header {
    padding: 9px 24px;
}

.add-phase-modal .ant-modal-close-x,
.add-task-modal .ant-modal-close-x {
    font-size: 13px;
    line-height: 44px;
    margin-right: 5px;
}

.add-task-modal .ant-modal-body {
    padding: 8px 16px;
}

.add-phase-modal .ant-modal-body {
    padding: 8px 16px 0 16px;
}

.add-phase-modal .ant-form-item,
.add-task-modal .ant-form-item {
    margin-bottom: 8px;
}

.add-task-modal .ant-radio-group {
    margin: 8px 0;
}

.add-task-modal .ant-checkbox + span {
    padding-left: 4px;
}

.add-task-modal .ant-radio-group > label {
    width: 62px;
    padding: 0 6.5px;
    height: 24px;
    line-height: 22px;
}

.add-task-modal .ant-modal-footer {
    padding: 2px 16px 10px;
    border-top: unset;
}

.add-phase-modal .ant-modal-footer {
    padding: 10px 16px;
    border-top: 1px solid #f0f0f0;
}

.add-phase-modal .ant-modal-footer > button,
.add-task-modal .ant-modal-footer > button {
    width: 90px;
}

.add-task-modal .user-item {
    height: 100%;
    display: flex;
    align-items: center;
}

.delete-phase-confirm-title {
    color: #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
}

.delete-phase-confirm-text {
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    margin-bottom: 8px;
}

.delete-phase-confirm-space,
.delete-phase-confirm-radio-group {
    width: 100%;
}

.delete-phase-confirm-select {
    width: 319px;
    margin: 8px 0 16px 24px;
}

.task-phase-collapse-panel {
    margin-bottom: 8px;
    margin-right: 4px;
}

.task-phase-collapse-panel .ant-collapse-content-box {
    padding: 8px 0;
    overflow-x: auto;
}

.task-phase-collapse-panel .ant-collapse-expand-icon {
    margin-top: 10px;
}

.task-phase-collapse-panel .ant-collapse-header {
    border: 1px solid #F0F0F0;
    background: #FFF;
}

.task-phase-collapse-panel .ant-collapse-header:hover {
    background: linear-gradient(0deg, #FAFAFA, #FAFAFA),
    linear-gradient(0deg, #F0F0F0, #F0F0F0);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 4px 8px;
}

.task-phase-button-divider {
    margin: 0 0 -3px 0;
    font-size: 32px;
}

.task-phase-button-edit {
    display: none;
}
.ant-collapse-item-active .task-phase-button-edit {
    display: inline-flex;
}

.task-phase-button-delete {
    display: none;
    margin-top: 1px;
    align-items: center;
}
.ant-collapse-item:not(.ant-collapse-item-active) .ant-collapse-header:hover .task-phase-button-delete {
    display: inline-flex;
}

.task-phase-collapse-panel-buttons {
    margin-top: 8px;
    height: 32px;
    display: flex;
    align-items: baseline;
}

.panel-phase-header {
    display: flex;
    width: 100%;
    height: 44px;
}

.panel-phase-header-view {
    display: inline-grid;
    justify-content: space-between;
}

.panel-phase-header-edit {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.panel-phase-name {
    color: #262626;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.panel-phase-tasks-count {
    color: #8C8C8C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.panel-phase-name-outlined {
    display: none;
    margin-left: 8px;
}

.panel-phase-name:hover .panel-phase-name-outlined {
    display: block;
}

.edit-phase-name-edit-icon-check,
.edit-phase-name-edit-icon-check:hover {
    color: #52C41A;
    margin-left: 10px;
}

.tasks-list-container .stats-container {
    display: flex;
    margin: 24px 0 29px 15px;
}

.tasks-list-container .search-container .command-buttons > button {
    margin-right: 4px;
}

.tasks-list-container .tasks-list .ant-table-content {
    overflow: auto;
    max-width: 100%;
}

.tasks-list-container .ant-table-tbody > tr > td:has(.task-name) {
    cursor: pointer;
}

.project-view-edit-form .estimate {
    width: 291px;
    margin-right: 16px;
}

.tasks-list-container .notification-informer {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FF4D4F;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -7px;
}

.tasks-list-container .notification-informer-empty {
    background: transparent;
}