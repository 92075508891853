.notification-item {
    padding: 8px;
    color: #8C8C8C;
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
    border-bottom: 1px solid #E7ECF3;
    width: 303px;
    box-sizing: content-box;
}

.notification-item .notification-item-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.notification-item .notification-item-header > div:first-child {
    min-width: 265px!important;
}

.notification-item .notification-action {
    margin-top: 8px;
}

.notification-item .notification-item-header > div:last-child {
    min-width: 50px;
    text-align: end;
    padding-right: 10px;
}

.notification-item > div:first-child {
    width: 228px;
    line-height: 17px;
}

.notification-item span {
    color: #000
}

.ant-popover-title {
    padding: 0;
    width: 333px;
    height: 32px;
}

.notification-header {
    width: 320px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px 4px 8px;
}

.notification-header > div:first-child {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    color: #000;
}

.notification-header > div:last-child {
    color: #1890FF;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
}

.ant-popover-inner-content {
    padding: 0;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
}