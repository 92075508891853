.template-summary {
    margin: 0 16px 16px 24px;
}
.template-position {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
}

.template-summary h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin: 0 0 10px;
}

.template-summary h2 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin: -8px 0 16px 0;
}

.template-summary .form-header {
    display: flex;
    justify-content: space-between;
}

.template-summary .project-view-edit-form {
    width: 367px;
}

.template-summary .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
}

.template-summary .lead-item {
    height: 100%;
    display: flex;
    align-items: center;
}

.template-summary .lead-item .profile-pic {
    margin-right: 4px;
}

.template-summary .upload-image-wrapper {
    border: 1px solid #F0F0F0;
    padding: 8px;
    width: 367px;
}

.template-summary .upload-image {
    background: #F3F5F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    height: 160px;
}

.template-summary .upload-image-active {
    height: 160px;
    width: 100%;
}

.template-summary .upload-image span {
    cursor: pointer;
    width: 100%;
}

.template-summary .upload-company-logo {
    width: 100%;
}

.template-summary .prevImage {
    width: 104px!important;
    height: unset!important;
}

.template-summary .upload-company-logo .ant-upload.ant-upload-select-picture-card {
    height: 160px;
    width: 100%;
}

.template-summary .form-suffix {
    margin-left: 8px;
}

.ant-select-item-option:has(.last-group) {
    border-bottom: 1px solid #F1F1F1;
}

.template-summary > .wrapper {
    display: flex;
}

.template-summary .vX-wrapper {
    margin-left: 16px;
    display: flex;
    width: 370px;
    height: 256px;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 2px;
    border: 1px solid #91D5FF;
    background: #E6F7FF;
    position: relative;
}

.template-summary .vX-wrapper .template-label {
    background: #9254DE;
    right: 0;
    color: #fff;
    width: 93px;
    height: 28px;
    font-size: 16px;
    text-align: center;
    margin: 0;
}

.template-summary .vX-wrapper > .header {
    margin: 8px 0 4px 0;
}

.template-summary .vX-wrapper > div {
    margin-top: 4px;
}

.template-summary .vX-wrapper > div > span {
    color: #1890FF;
    width: 16px;
    height: 16px;
    margin-right: 4px;
}