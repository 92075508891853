.vendors-list {
    height: 100%;
    padding: 16px 24px 16px 40px;
    color: #262626;
}
.vendors-list > h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 28px;
    color: #000000
}
.vendors-list > .stats-container {
    display: flex;
    margin: 5px 0 0 0;
}
.vendors-list > .stats-container > div > .ant-statistic > .ant-statistic-title {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
}
.vendors-list > .stats-container > div {
    margin: 8px 16px 14px -8px;
}
.vendors-list > .search-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
}

.vendors-list > .container-add-vendor {
    justify-content: flex-end;
}

.vendors-list > .search-container .command-buttons > button {
    margin-right: 8px;
    height: 32px;
}
.vendors-list > .search-container .command-buttons > button:last-child {
    margin-right: 0;
}
.vendors-list .vendor-table {
    margin-top: 8px;
}

.vendors-list .vendor-name {
    color: #262626;
}

.vendors-list .vendors-contact {
    color: #262626;
}

.vendors-list .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table-empty > .ant-table-container > .ant-table-body > table > .ant-table-tbody > .ant-table-placeholder {
    display: none;
}
.vendors-list-dropdown-underline {
    background: #F0F0F0;
    height: 1px;
    width: 185px;
    position: absolute;
    top: 32px;
    left: 0;
}
