html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
}

#root {
  overflow: hidden;
}