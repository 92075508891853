.rule-wrapper {
    background-color: #1C1E22;
    height: 100%;
}

.rule-wrapper > .rule-logo {
    padding-top: 42px;
    padding-left: 16px;
    margin-bottom: 24px;
    display: flex;
    cursor: pointer;
    justify-content: center;
}

.rule-wrapper > .rule-logo-short {
    display: flex;
    cursor: pointer;
    justify-content: center;
    padding-top: 45px;
    margin: 0 auto 16px;
}

.rule-wrapper .ant-menu.ant-menu-dark {
    background-color: #1C1E22;
}

.rule-wrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: inherit;
}

.rule-wrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected > span,
.rule-wrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected > span > span{
    color: #1890FF !important;
}

.rule-wrapper .main-menu .ant-menu-item {
    width: 216px!important;
    border-radius: 5px;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 16px!important;
}

.rule-wrapper .main-menu .ant-menu-item:first-child {
    margin-top: 8px;
}

.rule-wrapper .main-menu .ant-menu-item:last-child {
    margin-bottom: 8px;
}

.rule-wrapper .main-menu .ant-menu-item:hover,
.rule-wrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:hover
{
    background-color: #2E3238;
}

.rule-wrapper > .main-menu:not(.menu-collapsed) .ant-menu-item-group-title {
    padding-left: 32px;
    height: 40px;
    display: flex;
    align-items: center;
}

.rule-wrapper .ant-menu-item-group-title {
    font-size: 12px;
    color: #FFFFFF;
}

.rule-wrapper > .menu-collapsed:not(.main-menu) .ant-menu-item-group > .ant-menu-item-group-title {
    text-align: center;
}

.rule-wrapper .main-menu >ul>li:last-child > ul>li:last-child{
    position: absolute;
    margin: 0 16px;
    bottom: 26px;
}

.rule-wrapper .menu-collapsed >ul>li:last-child > ul>li:last-child {
    position: absolute;
    bottom: 26px;
}

.ant-dropdown-trigger .organization-name {
    display: flex;
    align-items: center;
    color: white;
}
.default-organization {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    align-items: center;
    display: flex;
}

.rule-wrapper > .menu-collapsed .default-organization {
    margin: 0 0 0 11px;
}

.rule-wrapper > .ant-menu-item-group > .ant-menu-item-group-list {
    position: absolute;
    bottom: 0;
}

.ant-layout-sider-children .image-non-collapsed {
    cursor: pointer;
    display: none;
    background: url('../../assets/images/show-menu-arrow.svg');
    width: 24px;
    height: 24px;
    position: absolute;
    top: 48px;
    right: -12px;
}
.ant-layout-sider-children .image-collapsed {
    cursor: pointer;
    display: none;
    background: url('../../assets/images/hide-menu-arrow.svg');
    width: 24px;
    height: 24px;
    position: absolute;
    top: 48px;
    right: -12px;
}

.rule-wrapper:hover .image-collapsed,
.rule-wrapper:hover .image-non-collapsed {
    display: block;
    z-index: 1;
}

.rule-wrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item > span > svg,
.rule-wrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item > span > span > svg{
    font-size: 19px;
}

.app-header-dropdown {
    position: relative;
    margin: 0;
    width: 156px;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.app-header-dropdown .ant-dropdown-menu {
    background: none;
    box-shadow: none;
}

.app-header-dropdown .search-wrapper {
    margin: 8px 10px 8px;
    width: 136px;
}

.app-header-dropdown .underline {
    border: 1px solid #F0F0F0;
}

.app-header-dropdown .empty-result-wrapper > .empty-result-image {
    background: url("../../assets/images/no-search-result.svg") no-repeat;
    width: 80px;
    height: 72px;
    margin: 16px auto 6px
}

.app-header-dropdown .empty-result-wrapper > .empty-result-title {
    text-align: center;
    padding-bottom: 20px;
    font-size: 12px;
    color:#8C8C8C;
    line-height: 20px;
    vertical-align: center;
}

.app-header-dropdown .search-item > span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #262626;
}
.app-header-dropdown .container-list {
    width: 156px;
}
.app-header-dropdown .search-item {
    padding: 5px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.app-header-dropdown .search-item:hover,
.app-header-dropdown .all-results:hover
{
    background-color: #f5f5f5;
    cursor: pointer;
}

.app-header-dropdown .all-results {
    padding: 5px 12px;
    margin-top: 4px;
    color: #262626;
}
.app-header-wrapper > div > .header-components .main-menu > ul > .ant-menu-item > .ant-menu-title-content > .ant-dropdown-open > div > .ant-space-item > span > svg {
    fill: #1890ff;
}

.app-header-wrapper > div > .header-components .main-menu > ul > .ant-menu-item > .ant-menu-title-content > a > div > .ant-space-item > span > svg {
    fill: #262626;
}

.app-header-wrapper > div > .header-components .main-menu > ul > .ant-menu-item {
    padding: 0 16.5px;
}

.app-header-wrapper > div > .header-components .clients-menu > ul > .ant-menu-item {
    padding: 0 16.5px 0 16.5px;
}

.app-header-dropdown .ant-input-search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn:hover > .anticon-search > svg {
    fill: #1890ff;
}

.app-header-dropdown .ant-input-search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn > .anticon-search > svg {
    fill: #262626;
}

.app-header-dropdown .ant-input {
    height: 24px;
}

.app-header-dropdown .ant-input-search-button {
    height: 24px;
    width: 24px;
}

.rule-wrapper > .menu-collapsed:not(.main-menu) .ant-menu-item {
    text-align: center;
}

.selected-org {
    font-size: 14px;
}

.menu-collapsed .ant-menu-title-content {
    display: none;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    width: 100%;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
    display: none;
}

.menu-collapsed .ant-menu-item .anticon {
    padding-top: 4px;
}

.rule-wrapper .ant-menu-item > span > svg,
.rule-wrapper .ant-menu-item > span > span > svg {
    color: #FFFFFF;
    font-size: 19px;
}

.rule-wrapper .ant-menu-item > span {
    display: flex;
}

.rule-wrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected > span > svg,
.rule-wrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected > span > span > svg {
    color: #1890FF;
    font-size: 19px;
}

.rule-wrapper .ant-menu-title-content {
    color: #a9a9a9;
}

.rule-wrapper .ant-menu-item-group:first-child {
    margin-bottom: 10px;
}

.rule-wrapper .projects-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.rule-wrapper .projects-wrapper > div {
    min-width: 25px;
    background-color: #1890FF;
    line-height: 22px;
    color: #ffffff;
    height: 22px;
    text-align: center;
    border-radius: 2px;
}