.query-editor-page {
    width: 100%;
    height: 100%;
    padding-top: 12px;
}

.query-editor-page .ant-tabs-nav-list {
    padding-left: 8px;
}

.query-editor-page .__dbk__child-wrapper,
.query-editor-page .__dbk__gutter.Vertical
{
    z-index: 100;
}