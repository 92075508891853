.not-created-page-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.not-created-page-wrapper > .image {
    background-image: url(../../assets/images/unconstructed.svg);
    width: 392px;
    height: 257px;
}

.not-created-page-wrapper > .header {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
    text-align: center;
    color: #262626;
}

.not-created-page-wrapper > .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: center;
    color: #8C8C8C;
}
