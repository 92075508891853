.list-page {
    overflow: auto;
    padding: 18px 24px 30px;
}

.list-page .table-container {
    height: 100%;
}

.list-page > .search-container {
    margin-top: 0;
}

.list-page > .empty-list-page-wrapper {
    height: calc(100% - 234px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.list-page .filter {
    border: 1px dashed #D9D9D9;
    height: 32px;
    width: 90px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 16px 0;
    cursor: not-allowed;
}

.list-page .filter > div > .plus {
    margin: 0 4px 0 10px;
}

.list-page > .filter > div {
    line-height: 22px;
    font-weight: 400;
    color: #262626;
}

.list-page > .search-container > .command-buttons > button:nth-child(4) {
    margin-left: 8px;
}

.list-page > h1 {
    position: relative;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 6px;
    margin-left: 0;
    font-size: 18px;
    font-family: "Lato", sans-serif;
}

.list-page > .empty-container > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table-empty > .ant-table-container > .ant-table-body > table > .ant-table-tbody > .ant-table-placeholder {
    display: none;
}

.list-page table tr th.ant-table-selection-column, .list-page table tr td.ant-table-selection-column {
    padding-left: 16px;
    padding-right: 0;
}

