.aws-form {
    margin-left: 24px;
}

.aws-form .desc {
    height: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #262626;
    margin-top: 32px;
    width: 545px;
    margin-bottom: 24px;
}

.aws-form .form-layout {
    display: flex;
}

.aws-form .form-layout .col-1 {
    width: 367px;
}

.aws-form .form-layout > .col-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px 0 24px;
}

.aws-form .form-layout > .col-3 {
    width: 431px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aws-form .form-layout .ant-form-item {
    margin-bottom: 16px!important;
}

.aws-form .upload-container {
    height: 298px;
}

.aws-form .test-connection {
    height: 40px;
    width: 168px;
}
