.users-list {
    height: 100%;
    padding: 16px 16px 16px 16px;
}

.users-list > .stats-container {
    display: flex;
    margin-left: 0;
}

.users-list > h1 {
    font-size: 20px;
    font-weight: 600;
    margin-left: 17px;
    color: #000000
}

.users-list > .stats-container > div {
    margin: 4px 23px 20px 17px;
}

.users-list > .search-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
}

.users-list > .search-container .command-buttons {
    display: flex;
    align-items: center;
}

.users-list > .search-container > div > .ant-input-search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn:hover > .anticon-search > svg {
    fill: #1890ff;
}

.users-list > .search-container > div > .ant-input-search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn > .anticon-search > svg {
    fill: #262626;
}

.users-list > .search-container > div > span > span > input {
    width: 174px;
}

.users-list > .search-container > .command-buttons > button {
    margin-right: 8px;
}

.users-list > .search-container > .command-buttons > button:nth-child(5) {
margin: 0 0 0 8px;
}

.users-list > .search-container > .command-buttons > button:nth-child(4) {
    width: 81px;
}


.users-list > .filter {
    border: 1px dashed #D9D9D9;
    height: 32px;
    width: 90px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: not-allowed;
}

.users-list > .filter > div > .plus {
    margin: 0 4px 0 10px;
}

.users-list > .filter > div {
    line-height: 22px;
    font-weight: 400;
    color: #262626;
}

.users-list table > tbody > tr > td > .name-col {
    display: flex;
    align-items: center;
    max-height: 23px;
    margin: 16px 0 16px 0;
}

.users-list table > tbody > tr > td > .name-col > span {
    background-color: #FFF0F6;
    color: #262626;
}

.users-list table > tbody > tr > td > .name-col > span:first-child{
    width: 32px !important;
    height: 32px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-right: 8px;
    text-transform: uppercase;
}

.users-list table > tbody > tr > td > .name-col > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.users-list table > tbody > tr > td > .name-col > div > div {
    color: #8C8C8C;
}

.users-list table > .ant-table-thead > tr > th:nth-child(2),
.users-list table > .ant-table-tbody > tr > td:nth-child(2){
    position: relative;
    padding: 16px 16px 16px 32px;
    overflow-wrap: break-word;
}

.users-list > .empty-container > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table-empty > .ant-table-container > .ant-table-body > table > .ant-table-tbody > .ant-table-placeholder {
    display: none;
}