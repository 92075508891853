.device-view-edit {
    margin-top: 20px;
}

.device-view-edit h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-left: 24px;
    margin-bottom: 10px;
}

.device-view-edit h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #171725;
    margin-left: 24px;
    margin-bottom: 16px;
}

.device-view-edit .device-view-edit-form {
    margin-left: 24px;
    width: 367px;
}

.device-view-edit .device-view-edit-form .ant-form-item-label label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
}

.device-view-edit .scan-details table {
    margin-left: 24px;
}

.device-view-edit .scan-details table tr th {
    text-align: left;
    padding-right: 19px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.0120588em;
    color: #ACA0A0;
    padding-bottom: 2px;
}

.device-view-edit .scan-details table tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #1B1B1B;
    padding-bottom: 2px;
}

.device-view-edit input {
    height: 40px;
}

