.user-summary > .title-img {
    background-color: #FFF0F6;
    color: #262626;
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    margin-right: 8px;
    letter-spacing: -2px;
}