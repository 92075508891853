.child-sidebar-page {
    height: 100%;
    overflow: hidden;
}

.child-sidebar-page > div:first-child {
    height: 60px;
    border-bottom: 1px solid #F2F2F2;
}

.child-sidebar-page > .devices-content {
    height: 100%;
}

.child-sidebar-page > div:last-child:not(.devices-content) {
    display: flex;
    height: calc(100% - 68px);
    margin: 4px 8px;
}

.child-sidebar-page > .user-page:last-child {
    margin-right: 0;
}

.child-sidebar-page .sidebar-layout {
    padding-top: 0;
    width: 100%;
}

.child-sidebar-page .ant-menu-sub.ant-menu-inline {
    background-color: #ffffff;
}