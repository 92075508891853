.project-status-report-schedule-modal .ant-modal-header {
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.project-status-report-schedule-modal .ant-modal-footer button {
    width: 90px;
}
.project-status-report-schedule-modal .ant-modal-close-x {
    width: 16px;
    height: 16px;
    line-height: 22px;
    margin-right: 24px;
}

.project-status-report-schedule-modal .ant-modal-title {
    color: #262626;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.project-status-report-schedule-frequency {
    width: 56px;
    margin: 0 8px;
    background: #FFF;
}

.project-status-report-schedule-periodicity-radio-group .ant-radio-button-wrapper {
    padding: 0 16px;
    background: #FFF;
}

.project-status-report-schedule-periodicity-radio-group .ant-radio-button-disabled {
    color: #262626;
    opacity: 0.5;
    box-shadow: 0 1px 0 0 #D9D9D9 inset, 0 -1px 0 0 #D9D9D9 inset;
}
