.documents-page-empty-search-response-container > div {
    color: #262626;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.documents-page-empty-search-response-container .ant-table-body:hover{
    cursor: pointer;
}

.documents-page-table-folder-record > span:first-child {
    margin-right: 10px;
}

.documents-page-table-folder-record,
.documents-page-table-folder-record:hover,
.documents-page-table-folder-record:visited,
.documents-page-table-folder-record:active {
    color: #262626;
}

.documents-page-table-document-name-column {
    display: flex;
    align-items: center;
}

.documents-page-table-document-name-column > :first-child {
    margin-right: 10px;
}

.documents-page-table-document-name-column > .document-download {
    color: #1890FF;
}

.overlay-dot-menu {
    width: 143px;
}