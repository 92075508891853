.comments-section {
    padding-bottom: 22px;
}

.comments-section .input-wrapper {
    padding: 8px 12px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #BFBFBF;
    margin-top: 15px;
}
.comments-section .input-wrapper > input {
    height: 22px;
}
.comments-section .input-wrapper > .icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.comments-section .input-wrapper > .icon-container > span {
    margin-right: 24px;
    width: 16px;
    height: 16px;
}
.comments-section .input-wrapper > .icon-container > span:nth-child(3) {
    margin-right: 16px;
}

.comments-section .input-wrapper > .ant-input:focus, .ant-input-focused {
    box-shadow: none;
}
.comments-section .input-wrapper > .ant-input {
    border: none;
    padding-left: 0;
}
