.page-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #000000;

}

.integrations-wrapper {
    display: flex;
    margin-left: 16px;
    height: calc(100% - 38px);
    border-top: 1px solid #F5F5F5;
}

.integrations-wrapper .ant-menu {
    height: 100%;
    padding-top: 20px;
    width: 220px;
}