.queries-menu-panel {
    height: calc(100% - 96px);
    overflow: hidden;
    width: 220px;
    /*overflow-x: hidden;*/
    /*overflow-y: auto;*/
}

.queries-menu .query-name {
    display: flex;
}

.queries-menu .query-name .query-label {
    width: 159px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.queries-menu .query-name .query-icons,
.query-edit-item .query-icons {
    display: flex;
    margin-left: 4px;
}

.queries-menu .query-name .query-icons {
    padding-top: 7px;
}

.queries-menu .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    height: calc(100% - 46px);
    overflow: hidden;
    overflow-y: auto;
}

.queries-menu .query-name .query-icons div:first-child,
.query-edit-item .query-icons div:first-child {
    margin-right: 4px;
}

.queries-menu-panel .ant-collapse-borderless {
    height: 100%;
}

.queries-menu-panel .ant-collapse {
    height: 100% !important;
}

.queries-menu-panel .ant-collapse-borderless > .ant-collapse-item:last-child {
    height: 100%;
}

.queries-menu-panel .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content
{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.query-edit-item {
    display: flex;
}

.query-edit-item .query-icons {
    display: flex;
}

.queries-menu .ant-menu-inline {
    border-right: 0;
}
