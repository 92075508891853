.project-chat {
    width: calc(100% - 15px);
    height: calc(100% - 85px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 24px 16px 24px;
}

.project-chat .messages {
    overflow: auto;
    height: 100%;
}

.project-chat .message-input {
    padding-top: 16px;
}

.project-chat .task-comments {
    border-top: 0;
    height: calc(100% - 33px);
}

.project-chat .message-input .input-wrapper {
    padding: 8px 12px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #BFBFBF;
}

.project-chat .message-input .input-wrapper > input {
    height: 22px;
    background-color: #FFFFFF;
}

.project-chat .message-input .input-wrapper > .icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.project-chat .message-input .input-wrapper > .icon-container > span {
    margin-right: 24px;
    width: 16px;
    height: 16px;
}
.project-chat .message-input .input-wrapper > .icon-container > span:nth-child(3) {
    margin-right: 16px;
}

.project-chat .message-input .input-wrapper > .ant-input:focus, .ant-input-focused {
    box-shadow: none;
}
.project-chat .message-input .input-wrapper > .ant-input {
    border: none;
    padding-left: 0;
}

.project-chat .spinner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-chat .load-more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    margin-bottom: 8px;
    cursor: pointer;
    color: #262626;
}

.project-chat .load-more > span {
    margin-right: 4px;
}

.project-chat .load-more > div {
    margin-left: 4px;
}