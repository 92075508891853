.summary-header {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding-left: 35px;
    color: #262626;
}

.summary-header img {
    cursor: pointer;
}

.summary-header > div:last-child {
    margin-right: 32px;
}

.summary-header > div:last-child > button:first-child {
    margin-right: 8px;
}

.summary-header > div:last-child > span:first-child {
    margin-right: 8px;
}

.summary-header .query-icon {
    margin-left: 11px;
    margin-right: 5px;
}

.summary-header .name-wrapper {
    display: flex;
}

.summary-header .name-wrapper > div > img {
    width: 32px;
}

.summary-header .name-wrapper > .scan-name {
    display: flex;
    align-items: center;
}

.summary-header .buttons {
    display: flex;
}

.summary-header .header-additional-button {
    margin-right: 32px;
}

.summary-header .header-buttons {
    display: flex;
}

.summary-header .header-buttons .button {
    margin-right: 8px;
}
