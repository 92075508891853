.documents-page-container {
    margin: 18px 34px 0 24px;
    height: calc(100% - 97px);
    display: flex;
    flex-direction: column;
    position: relative;
}

.documents-page-container .documents-empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 75px);
    position: absolute;
    top: 75px;
    bottom: 500px;
}

.documents-page-container .sidebar-opened {
    width: calc(100% - 431px) !important;
}

.documents-page-container .no-sidebar {
    width: 100% !important;
}

.documents-page-container .documents-table-container {
    display: flex;
    height: calc(100% - 135px);
}