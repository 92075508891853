.mapping-grid a.empty {
    color: #D9D9D9!important;
}

.mapping-grid a.non-empty {
    color: #262626!important;
}

.mapping-grid > .header {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    align-items: center;
    color: #8C8C8C;
    margin-bottom: 14px;
}

.mapping-grid > .row {
    display: flex;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    align-items: center;
    color: #5B5252;
    justify-content: space-between;
    padding-right: 25px;
}

.mapping-grid > .row > div:first-child {
    display: flex;
    width: 100%;
}

.mapping-grid > .row > div > div {
    display: flex;
    align-items: center;
}

.mapping-grid > .row > div > div:nth-child(2) {
    padding-right: 4px;
}

.mapping-grid > .header > div:first-child,
.mapping-grid > .row > div > div:first-child
{
    width: 73px;
    min-width: 73px;
}

.mapping-grid > .header > div:last-child {
    width: 100%;
    margin-left: 16px;
}

.mapping-grid .select-mapping-item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #5B5252;
}

.mapping-grid .align-vertical {
    align-items: center;
    display: flex;
}

.mapping-grid .row-custom {
    width: 100%;
}

.mapping-grid .row-custom > div:last-child {
    width: 152px;
    max-width: 152px;
    min-width: 152px;
}

.mapping-grid .row-custom > div > div:last-child {
    width: 152px;
    max-width: 152px;
}

.mapping-grid .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px;
    padding-left: 7px;
}

.mapping-grid .ant-select-single .ant-select-selector .ant-select-selection-item,
.mapping-grid .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 22px;
}

.mapping-grid .empty-select {
    color: #BFBFBF;
}