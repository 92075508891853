.sidebar-layout-container {
    padding: 0!important;
}

.sidebar-layout {
    display: flex;
    height: 100%;
    width: 100%;
}
.sidebar-layout .full-height .menu-sidebar {
    padding-top: 44px;
}
.sidebar-layout .full-height .image-non-collapsed {
    cursor: pointer;
    display: none;
    background: url('../../assets/images/show-menu-arrow.svg');
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    right: -12px;
}
.sidebar-layout .full-height .image-collapsed {
    cursor: pointer;
    display: none;
    background: url('../../assets/images/hide-menu-arrow.svg');
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    right: -12px;
}

.sidebar-layout .full-height {
    height: 100%;
    position: relative;
}

.sidebar-layout .full-height:hover .image-collapsed,
.sidebar-layout .full-height:hover .image-non-collapsed {
    display: block;
}


.sidebar-layout > div:last-child {
    width: 100%;
    overflow: hidden;
}

.sidebar-layout .ant-menu-sub.ant-menu-inline {
    background-color: #F0F0F0;
}

.child-sidebar-page .sidebar-layout .ant-menu-sub.ant-menu-inline {
    background-color: #fff;
}

.child-sidebar-control {
    border: 1px solid red;
}