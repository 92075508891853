.scan-spin {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scan-menu-control {
    height: 100%;
    border-right: 1px solid #F2F2F2;
}

.scan-menu-control .ant-menu-inline {
    border-right: 0;
}