.device-summary-header {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding-left: 35px;
    color: #262626;
}

.device-summary-header img {
    cursor: pointer;
}

.device-summary-header > div {
    height: 100%;
    display: flex;
    align-items: center;
}

.device-summary-header > div:last-child {
    margin-right: 32px;
}

.device-summary-header > div:last-child > button:first-child {
    margin-right: 8px;
}

.device-summary-header .query-icon {
    margin-left: 11px;
    margin-right: 5px;
    align-items: center;
    background: #fff;
    border: 1px solid #edeeef;
    border-radius: 8px;
    display: flex;
    height: 32px;
    justify-content: center;
    position: relative;
    top: -2px;
    width: 32px;
}

.device-summary-header .title-img {
    height: 21px;
    margin: 4px 7px 6px;
    width: 18px;
}

.device-summary-header .icon-status {
    border-radius: 4px;
    bottom: -4px;
    height: 8px;
    position: absolute;
    right: -3px;
    width: 8px;
}



