.debug-scan-results {
    height: 100%;
    display: flex;

}

.debug-scan-results .results-description-progress {
    flex-direction: column;
}

.debug-scan-results .results-description-progress > div:first-child,
.debug-scan-results .results-container .results-description > span:first-child
{
    margin-left: 15px;
}

.debug-scan-results .results-container .results-description {
    align-items: center;
    margin-bottom: 36px;
}

.debug-scan-results .results-data {
    height: 100%;
}

.debug-scan-results .empty-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.debug-scan-results .ant-tabs-nav-wrap {
    margin-left: 16px;
}

.debug-scan-results .results-description-progress > div:first-child,
.debug-scan-results .results-description > span:first-child {
    margin-left: 43px!important;
}

.debug-scan-results .results-table {
    margin: 20px 40px 20px 42px;
}

.debug-scan-results .results-description {
    display: flex;
    align-items: center;
}

.debug-scan-results .results-title {
    margin: 20px 0 0 42px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    height: 23px;
}

.debug-scan-results .results-description, .results-container .results-description-progress {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    display: flex;
    width: 100%;
    position: relative;
    top: -10px;
}

.debug-scan-results .debug-scan-results-column {
    word-break: break-word;
}

.debug-scan-results .results-container-panel {
    display: flex;
    height: 100%;
    overflow: auto;
}

.debug-scan-results .row-timeout
{
    background: #FFF1B8!important;
    color: #D48806;
}

.debug-scan-results .row-timeout td,
.debug-scan-results .row-error td {
    white-space: nowrap;
}

.debug-scan-results tr.row-timeout:hover > td {
    background: #FFF1B8!important;
    color: #D48806;
}

.debug-scan-results .row-error
{
    background: #FFF1F0!important;
    color: #FF4D4F;
}

.debug-scan-results tr.row-error:hover > td {
    background: #FFF1F0!important;
    color: #FF4D4F;
}

.error-tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 8px;
    border-radius: 2px;
    height: 22px;
    font-size: 12px;
}

.error-tag img {
    margin-right: 4px;
}

.tag-error {
    width: 67px;
    background: #FFF1F0;
    border: 1px solid #FFA39E;
}

.tag-timeout {
    width: 88px;
    background: #FFF1B8;
    border: 1px solid #FFD666;
}

.debug-scan-results .error-wrapper {
    display: flex;
    align-items: center;
}
.debug-scan-results .error-wrapper .error-text {
    margin-left: 16px;
    white-space: break-spaces;
}
