.query-inventory-container {
    padding: 20px 24px 22px 0;
}

.query-inventory-container:first-child {
    border-bottom: 1px solid #edeeef;
}

.query-inventory-container > .inventory-edit-popup {
    width: 220px;
    height: 358px;
    max-height: 358px;
    overflow: hidden;
    background: #FFFFFF;
    border: 1px solid #EDEEEF;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    position: absolute;
    z-index: 99999;
    right: 263px;
    margin-top: -10px;
}

.query-inventory-container > .inventory-edit-popup > div:first-child {
    height: 100%;
}

.query-inventory-container > .inventory-edit-popup > .inventory-search {
    height: 37px;
    border-bottom: 1px solid #EDEEEF;
    display: flex;
    align-items: center;
    padding: 0 12px;
    position: relative;
}

.query-inventory-container > .inventory-edit-popup .inventory-search img {
    margin-right: 12px;
}

.query-inventory-container > .inventory-edit-popup .inventory-search {
    padding: 8px 10px 8px 10px;
}

.query-inventory-container > .inventory-edit-popup .inventory-search input {
    height: 24px;
}

.query-inventory-container > .inventory-edit-popup .ant-input-search-button {
    height: 24px;
    width: 24px;
}

.query-inventory-container > .inventory-edit-popup .inventory-search input::placeholder {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8C8C8C;
}

.query-inventory-container > .inventory-edit-popup .inventory-edit-header {
    height: 40px;
    border-bottom: 1px solid #EDEEEF;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #262626;
    font-weight: 600;
    padding-left: 9px;
    padding-right: 16px;
}

.query-inventory-container > .inventory-edit-popup .inventory-edit-header button {
    border: 1px;
}

.query-inventory-container > .inventory-edit-popup .inventory-edit-header img {
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.query-inventory-container > .inventory-edit-popup .inventory-tree {
    height: calc(100% - 80px);
    overflow: hidden;
    overflow-y: auto;
    padding-top: 8px;
}

.query-inventory-container > .inventory-header {
    min-width: 232px;
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
}

.query-inventory-container > .inventory-header > span:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #5B5252;
}

.query-inventory-container > .inventory-header > span:last-child > span {
    width: 18px;
    height: 18px;
    background: #6A37C9;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.query-inventory-container > .inventory-header > span:last-child > span > img {
    width: 10px;
    height: 10px;
}

.query-inventory-container > .inventory-items {
    padding-left: 12px;
}

.query-inventory-container > .inventory-items > div {
    margin-top: 12px;
    display: flex;
    position: relative;
}

.query-inventory-container > .inventory-items > div > .platform-icon {
    position: absolute;
}

.query-inventory-container > .inventory-items > div > span:not(.cloud) {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #5B5252;
    margin-left: 22px;
    width: 175px;
    max-width: 175px;
}

.query-inventory-container > .inventory-items > div > .cloud {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
}

.query-inventory-container > .inventory-items > div > span > span:first-child {
    max-width: 118px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
}

.query-inventory-container > .inventory-items > div > span:last-child {
    width: 12px;
    cursor: pointer;
}

.query-inventory-container .inventory-edit-popup .ant-tree-node-content-wrapper {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.query-inventory-container .spin {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}