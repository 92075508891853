.report-preview .view-all-wrapper > .view-all {
    text-align: center;
    color: #1890FF;
    cursor: pointer;
}

.report-preview .collapse {
    width: 100%;
    border: 1px solid  #f0f0f0;
    margin-bottom: 16px;
    color: #40A9FF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    cursor: pointer;
}

.report-preview .collapse:hover {
    border: 1px solid #40A9FF;
}

.report-preview .collapse > .collapse-text {
    margin-left: 10px;
    font-size: 14px;
}

.report-preview .view-all-wrapper {
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: center;
    border-right: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}