@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Regular.eot) format('opentype');
  src: local('Lato'), url(./fonts/Lato-Regular.ttf) format('truetype');
  src: local('Lato'), url(./fonts/Lato-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('RobotoMono-Medium'), url(./fonts/RobotoMono-Medium.eot) format('opentype');
  src: local('RobotoMono-Medium'), url(./fonts/RobotoMono-Medium.ttf) format('truetype');
  src: local('RobotoMono-Medium'), url(./fonts/RobotoMono-Medium.woff) format('woff');
}

:root {
  --main-bg: #FFFFFF;
  --box-shadow-color: #EFEFEF;
  --border-container-color: #EDEEEF;
  --text-blue: #1890FF;
  --text-blue-2: #447AA0;
  --text-black: #000000;
  --text-black-2: #171725;
  --text-grey: #9E9EA6;
  --text-white: #FFFFFF;
  --text-violet: #6A37C9;
  --text-violet-2: #65428C;
  --text-dark-grey: #44444F;
  --text-grey-2: #8A8A8A;
  --text-grey-3: #A49B9B;
  --text-grey-4: #92929D;
  --text-green: #4C826B;
  --text-black-3: #141414;
  --text-purple-2: #CF5773;
  --text-light-grey: rgba(0, 0, 0, 0.25);
  --text-purple: #6A37C9;
  --bg-pink: #DFB6E2;
  --bg-pink-2: rgba(106, 55, 201, 0.08);
  --bg-pink-3: #F7D2DC;
  --bg-light-blue: rgba(24, 144, 255, 0.08);
  --bg-light-blue-2: #E6F7FF;
  --bg-grey: #B4B4B4;
  --bg-grey-2: #92929D;
  --bg-light-grey: #F3F3F4;
  --bg-green: #5FD06A;
  --bg-green-2: #3DD598;
  --bg-green-3: #C2E8D9;
  --bg-green-4: #4C826B;
  --bg-blue: #1890FF;
  --bg-blue-2: #C7E8FD;
  --bg-white: #F8FAFA;
  --bg-purple: #6A37C9;
  --border-blue: #1890FF;
  --border-grey: #D9D9D9;
  --border-violet: #6A37C9;
  --border-light-grey: #F1F1F5;
  --border-radius-10: 10px;
  --border-radius-8: 8px;
  --border-radius-6: 6px;
  --default-font: Lato, sans-serif;
}

/* fonts */
html, body, div {
  font-family: var(--default-font);
}

.monaco-editor .view-line {
  font-weight: 500!important;
  font-family: "Roboto Mono", serif !important;
}

.weight-700 {
  font-family: var(--default-font);
  font-weight: 700;
}

.weight-400 {
  font-family: var(--default-font);
  font-weight: 700;
}

.weight-500 {
  font-family: var(--default-font);
  font-weight: 500;
}

/* text */

.text-black {
  color: var(--text-black);
}

.text-black-2 {
  color: var(--text-black-2);
}

.text-blue {
  color: var(--text-blue);
}

.text-blue-2 {
  color: var(--text-blue-2);
}

.text-light-grey {
  color: var(--text-light-grey);
}

.text-purple-2 {
  color: var(--text-purple-2);
}

.text-grey {
  color: var(--text-grey);
}

.text-grey-2 {
  color: var(--text-grey-2);
}

.text-grey-3 {
  color: var(--text-grey-3);
}

.text-white {
  color: var(--text-white);
}

.text-dark-grey {
  color: var(--text-dark-grey);
}

.text-green {
  color: var(--text-green);
}

.text-violet {
  color: var(--text-violet);
}

.text-violet-2 {
  color: var(--text-violet-2);
}

/* backgrounds */

.bg-pink {
  background-color: var(--bg-pink);
}

.bg-pink-2 {
  background-color: var(--bg-pink-2);
}

.bg-pink-3 {
  background-color: var(--bg-pink-3);
}

.bg-light-blue {
  background-color: var(--bg-light-blue);
}

.bg-light-blue-2 {
  background-color: var(--bg-light-blue-2);
}

.bg-grey {
  background-color: var(--bg-grey);
}

.bg-green {
  background-color: var(--bg-green);
}

.bg-green-2 {
  background-color: var(--bg-green-2);
}

.bg-green-3 {
  background-color: var(--bg-green-3);
}

.bg-blue {
  background-color: var(--bg-blue);
}

.bg-blue-2 {
  background-color: var(--bg-blue-2);
}

.bg-light-grey {
  background-color: var(--bg-light-grey);
}

.bg-grey-2 {
  background-color: var(--bg-grey-2);
}

.bg-white {
  background-color: var(--bg-white);
}

/* borders */

.border-blue {
  border: 1px solid var(--border-blue);
}

.border-grey {
  border: 1px solid var(--border-grey);
}

/* components */

body {
  font-family: var(--default-font);
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: var(--text-black-2);
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: var(--text-black-2);
}

a {
  cursor: pointer;
  color: #0074D9;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  margin: 17px;
}

.text-right {
  display: flex;
  justify-content: flex-end!important;
  padding-right: 56px!important;
  position: relative;
}

.text-right img {
  position: absolute!important;
  top: 15px!important;
  right: 94px;
}

.user-pic {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-green-3);
  color: var(--text-green);
}

button:disabled,
button[disabled]
{
  color: #D5CBF3;
  justify-content: center;
  background: #d5cbf3;
}

.ant-layout-sider {
  overflow-y: auto;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #B5B5BE;
  background-color: transparent;
}

.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
  border-color: #B5B5BE;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-tree-checkbox-checked .ant-three-checkbox-inner {
  background-color: transparent;
  border-color: #B5B5BE;
}

.ant-tree-checkbox-checked:hover
{
  border-color: #B5B5BE;
}

.ant-checkbox, .ant-checkbox-input, .ant-checkbox-wrapper {
  background-color: transparent;
  border-color: #6A37C9!important;
  border-radius: 2px;
}

.ant-checkbox-checked {
  background-color: #1890ff;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #6A37C9;
  border-color: #6A37C9;
}

.ant-tree-checkbox-checked::after {
  border-color: #6A37C9;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: #6A37C9;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #FFFFFF;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-checkbox-wrapper:hover, .ant-checkbox-checked, .ant-checkbox-checked:hover {
  border-color: red!important;
}

.form-error input, .form-error input:focus,
.form-error input:hover, .form-error input:active,
.form-error input:focus-visible {
  outline: 0!important;
  -webkit-box-shadow: 0 0 0 1px #FB4E4E;
  box-shadow: 0 0 0 1px #FB4E4E;
  border-color: #FB4E4E!important;
}

.error-description {
  color: #FB4E4E;
  font-size: 14px;
  line-height: 16px;
  margin-top: 3px;
  height: 16px;
}

input:focus {
  outline: 0;
}

.ant-tooltip-inner {
  border-radius: 6px;
}

.animated-gradient {
  background: repeating-linear-gradient(to right,#6A37C9 0%, green 50%, red 100%);
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 5s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 99999;
}

.button-view {
  display: flex;
  width: 114px;
  height: 37px;
  justify-content: center;
  align-items: center;
  background: #F8F8F8;
  border-radius: 8px;
}

.button-view > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 34px;
  border-radius: 8px;
  margin: 0;
}

.button-view > div:first-child {
  margin-right: 2px;
}

.button-view > div.tableView {
  background-image: url("./assets/images/table.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-color: #F8F8F8;
}

.button-view > div.thumbView {
  background-image: url("./assets/images/thumbnail.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.button-view > div.tableView-selected {
  background-image: url("./assets/images/table-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #6A37C9;
}

.button-view > div.thumbView-selected {
  background-image: url("./assets/images/thumbnail-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #6A37C9;
}

.column-text-left {
  justify-content: left!important;
  margin-left: 40px!important;
}

.column-margin-right {
  position: relative;
}

.column-margin-right > span {
  position: absolute;
  right: 51px;
}

.column-margin-right > img {
  position: absolute!important;
  right: 20px!important;
  top: 16px!important;
}

.scan-list-table-row .scan-type {
  margin-left: 7px;
}

.type-switcher {
  background: #F8FAFA;
  border-radius: 8px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
}

.type-switcher > div {
  padding: 5px 12px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  display: flex;
}

.type-switcher .switcher-border {
  width: 1px;
  height: 24px;
  border-right: 1px solid #E3E3E3;
  padding: 0;
}

.type-switcher > div.selected {
  background: #6A37C9;
  border-radius: 8px;
  color: #FFFFFF;
  padding-right: 13px;
}

.type-switcher > div.selected:hover {
  background: #6A37C9;
}
.type-switcher > div:hover {
  background: rgba(106, 55, 201, 0.08);
  border-radius: 8px;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
}

.empty-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-disabled {
  background: #F5F5F5!important;
  border: 1px solid #D9D9D9!important;
  color: #BFBFBF!important;
}

.timeout-label {
  background-color: #FFF1B8;
  padding: 0 8px;
  height: 26px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  color: #D48806;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-left: 18px;
}

.failed-label {
  background: #FFF1F0;
  padding: 0 8px;
  height: 26px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  color: #F5222D;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-left: 18px;
}

.timeout-tags {
  background-color: #FFF1B8;
  border: 1px solid #FFD666;
  color: #D48806;
  height: 22px;
  padding: 1px 8px;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin: 0 32px;

}

.failed-tags {
  background: #FFF1F0;
  border: 1px solid #FFA39E;
  height: 22px;
  padding: 1px 8px;
  border-radius: 2px;
  color: #F5222D;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin: 0 32px;
}

.result-tag {
  display: flex;
  align-items: center;
}

.result-tag > img {
  margin-right: 5px;
}

.active-search-input {
  border: 1px solid #6A37C9!important;
}

.status-online {
  width: 11px;
  height: 11px;
  background-color: #008800;
  border-radius: 5px;
  display: inline-block;
}

.status-offline {
  width: 11px;
  height: 11px;
  background-color: red;
  border-radius: 5px;
  display: inline-block;
}

.status-unknown {
  width: 11px;
  height: 11px;
  background-color: #C7C7C7;
  border-radius: 5px;
  display: inline-block;
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}


.sidebar-layout > div > ul > .ant-menu-item:first-child {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 600;
}

.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
  right: 23px !important;
}

button {
  height: 32px;
}

.ant-form-vertical .ant-col {
  width: 100%!important;
}

/* Antd Tables */

.ant-table-container {
  border: 1px solid #F0F0F0;
}

.ant-table-header {
  border-bottom: 1px solid #F0F0F0;
}

.ant-table-header .ant-table-cell {
  font-weight: 600;
  font-size: 14px;
  color: #262626;
}

.ant-table-tbody .table-action img {
  cursor: pointer;
}

.ant-table-tbody .table-action a {
  width: 24px;
  height: 24px;
  display: flex;
  padding-left: 8px;
  align-items: center;
  justify-content: center;
}

.ant-table-body {
  overflow-y: auto!important;
}

.ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 6.5px 8px;
  height: 38px;
}

.ant-table-thead > tr > th {
  padding: 7px 8px;
  height: 38px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 0 8px!important;
}

.no-breaks {
  white-space: nowrap;
}