.project-template-slide-item {
    width: 241px;
    height: 260px;
    position: relative;
    padding: 16px;
    border-radius: 2px;
    background: #FFF;
    color: #0a0a0a;
    display: inline-block;
    border: 1px solid #F0F0F0;
}

.project-template-slide-item .template-label {
    position: absolute;
    top: 16px;
    background: #9254DE;
    right: 0;
    color: #fff;
    width: 93px;
    height: 28px;
    font-size: 16px;
    text-align: center;
}

.project-template-slide-item:hover {
    border-radius: 2px;
    border: 1px solid #1890FF;
    position: relative;
}

.project-template-slide-item .template-image > img {
    width: 209px;
    height: 100px;
    margin-bottom: 16px;
    object-fit: cover;
}

.project-template-slide-item .template-title {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;
}

.project-template-slide-item .template-description {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #84818A;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
    height: 44px;
    max-height: 44px;
}

.project-template-slide-item .template-modification {
    display: flex;
    margin-top: 8px;
}

.project-template-slide-item .template-modification > button:first-child {
    margin-right: 16px;
}

.project-template-slide-item .template-request {
    display: none;
}

.project-template-slide-item:hover > .template-request {
    cursor: pointer;
    display: block;
    position: absolute;
    width: 207px;
    border: 0;
}

.project-template-slide-item:hover > .template-request > span {
    margin-right: 8px;
}