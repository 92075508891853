.template-summary-page {
    height: calc(100% - 18px);
}

.template-summary-page .template-summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #262626;
    margin: 18px 16px 29px 16px;
}

.template-summary-page .template-summary-header div {
    display: flex;
    align-items: center;
}

.template-summary-page .template-summary-header .template-name {
    margin-left: 11px;
}

.template-summary-page .template-layout {
    border-top: 1px solid #F5F5F5;
    margin-left: 16px;
}

.template-summary-page .template-layout-menu {
    border-right: 1px solid #F5F5F5;
    margin-top: 4px;
    margin-left: 16px;
}

.template-summary-page .form-header {
    margin-top: 18px;
    width: calc(100% - 16px);
}

.template-summary-page .button-placeholder {
    width: 32px;
    height: 32px;
}

.template-summary-page-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-summary-page .templates-tasks-list-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-summary-page .templates-tasks-list-empty-search {
    height: calc(100% - 105px);
}

.template-summary-page .template-tasks-layout {
    height: calc(100% - 77px);
    border-top: 1px solid #f5f5f5;
}

.template-summary-page .sidebar-layout > div:last-child {
    overflow: auto;
}

.template-summary-page .tasks-list-container {
    margin-right: 18px;
}