.ant-layout {
    height: 100%;
}
.app-container {
    background-color: var(--main-bg);
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    height: calc(100% - 54px);
}

.app-container.users {
    overflow-y: hidden;
}

.ant-layout-sider.ant-layout-sider-dark.layout-sider {
    width: unset!important;
    max-width: unset!important;
    min-width: unset!important;
    flex: unset!important;
    overflow: visible;
}
.bordered {
    border-bottom: 1px solid var(--border-container-color);
}

.display-none {
    display: none;
}

.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    padding-right: 34px;
    padding-left: 17px;
    height: 54px;
}

.ant-layout-header > div:last-child {
    display: flex;
    align-items: center;
}

.ant-layout-header > div:first-child span {
    color: #8C8C8C;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.ant-layout-header > div:first-child > span:last-child {
    color: #262626;
}

.ant-layout-header > div:first-child .separator {
    margin: 0 8px;
}

.ant-layout-header > div:first-child .title {
    margin-left: 4px;
}

.no-line-height {
    line-height: unset;
}

.password-changed-modal .password-changed-modal-content {
    display: flex;
    flex-direction: row;
    width: 472px;
    margin: 8px 8px 10px 8px;
    color: #262626;
}

.password-changed-modal .password-changed-modal-content > div:first-child {
    margin-right: 16px;
}

.password-changed-modal .password-changed-modal-content > div:last-child {
    width: 432px;
}
.password-changed-modal .password-changed-modal-footer {
    width: 472px;
    text-align: right;
}

.password-changed-modal .password-changed-modal-content > div > div:first-child {
    color: #262626;
    font-family: Lato, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
}