.projects-templates-modal {
    height: 594px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 1058px;
}

.projects-templates-modal .empty-modal {
    height: 492px;
    right: 12px;
    position: relative;
}

.projects-templates-modal .templates-container {
    width: 1050px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 23px;
}

.projects-templates-modal .title {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    margin-bottom: 18px;
}

.projects-templates-modal .project-template-slide-item:nth-child(4n) {
    margin-right: 0;
}