.project-status-report-container {
    margin: 18px 34px 0 24px;
    height: 100%;
}

.project-status-report-container > .project-status-report-title {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 18px 0;
    color: #000000;
    font-weight: 600;
}

.project-status-report-controls {
    width: 100%;
    height: unset;
    margin: 0 0 18px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-status-report-controls-left {
    display: flex;
    align-items: center;
    gap: 32px;
}

.project-status-report-controls-right {
    display: flex;
    align-items: center;
    gap: 8px;
}

.project-status-report-date-container {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.project-status-report-date-select {
    width: 87px;
}

.project-status-report-schedule-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.project-status-report-schedule-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
