.upload-container {
    display: flex;
}

.upload-container > .upload-wrapper,
.upload-container > .upload-wrapper-active,
.upload-container > .upload-wrapper-uploaded,
.upload-container > .upload-wrapper-error
{
    width: 431px;
    height: 100%;
    background: #FAFAFA;
    border: 1px dashed #F1F1F1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-container > .upload-wrapper-active
{
    border: 1px dashed #1890FF;
}

.upload-container > .upload-wrapper-error {
    border: 1px dashed #F5222D;
}

.upload-container > div  > div > .error-text{
    color: #F5222D;
}

.upload-container > div  > div > .icon-error {
    background: url(../../assets/images/warning.svg) no-repeat;
    margin-right: 3px;
    width: 34px;
    height: 20px;
}

.upload-container > div  > .error-wrapper {
    display: flex;
    width: 317px;
    margin-top: 15px;
}

.upload-container > div > .file-wrapper {
    display: flex;
    width: 317px;
    justify-content: space-between
}

.upload-container > div > .file-wrapper > .icon-wrapper > .icon-file {
    background: url(../../assets/images/file.svg) no-repeat;
    margin-right: 9px;
    width: 17px;
    height: 21px;
}

.upload-container > div > .file-wrapper > .icon-wrapper {
    display: flex;
}

.upload-container > div > .file-wrapper > .icon-wrapper > .file-name {
    font-weight: 400;
    line-height: 21px;
    max-width: 258px;
    overflow-wrap: break-word
}

.upload-container > div > .upload-image {
    background-image: url(../../assets/images/group.svg);
    background-repeat: no-repeat;
    min-width: 131px;
    min-height: 93px;
}

.upload-container > div > .upload-image-description > .link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    color: #1890FF;
}

.upload-container > div > .file-wrapper > .remove-file {
    min-width: 24px;
    width: 24px;
    height: 24px;
}

.upload-container > div > .upload-image-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    margin-top: 9px;
}
