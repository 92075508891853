.document-comments {
    width: 100%;
    height: 100%;
    margin-bottom: 18px;
    padding-top: 8px;
    border-top: 1px solid #F5F5F5;
}

.document-comments .task-comment-container {
    display: flex;
}