.clients-list-container {
    height: calc(100% - 30px);
    margin: 0 16px;
    overflow: hidden;
    display: flex;
}
.company-add-edit-modal .ant-modal-header .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 24px;
    align-items: center;
}

.company-add-edit-modal .ant-modal-body {
    padding: 16px 24px 24px 24px;
}
.search-container {
    margin-top: 15px;
    display: flex;
    height: 33px;
    justify-content: space-between;
}

.clients-list-container .stats-container {
    margin: 8px 0 0 -8px;
}

.search-container > div {
    display: flex;
    justify-content: center;
}

.search-container input[type='text'],
.search-container button {
    height: 32px;
}

.search-container input[type='text'] {
    width: 174px;
}

.search-container .command-buttons > button {
    margin-right: 16px;
    height: 33px;
}

.clients-list-container .search-container .command-buttons > button:last-child {
    margin-right: 0;
    position: relative;
}

.clients-list-container .search-container .command-buttons > button:nth-child(2) {
    margin-right: 8px;
    position: relative;
}

.table-container {
    margin-top: 8px;
    width: 100%;
    overflow: hidden;
}

.empty-container {
    margin-top: 8px;
}

.ant-table-tbody > tr > :nth-child(4) {
    padding-left: 0;
}

.clients-list-container .empty-container > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table-empty > .ant-table-container > .ant-table-body > table > .ant-table-tbody > .ant-table-placeholder {
    display: none;
}

.clients-list-container .ant-table-thead .ant-table-cell:nth-child(2),
.clients-list-container .ant-table-thead .ant-table-cell:nth-child(3),
.clients-list-container .ant-table-thead .ant-table-cell:nth-child(4),
.clients-list-container .ant-table-thead .ant-table-cell:nth-child(5)
{
    text-align: right;
}

.clients-list-container .ant-table-tbody .ant-table-cell:nth-child(2),
.clients-list-container .ant-table-tbody .ant-table-cell:nth-child(3),
.clients-list-container .ant-table-tbody .ant-table-cell:nth-child(4),
.clients-list-container .ant-table-tbody .ant-table-cell:nth-child(5)
{
    text-align: right;
    padding-right: 15px;
}

.clients-list-container .ant-table-thead .ant-table-cell:nth-child(6),
.clients-list-container .ant-table-tbody .ant-table-cell:nth-child(6) {
}

.clients-list-container .ant-dropdown-menu-item a:hover {
    text-decoration: none;
}

.error {
    color: red;
    font-size: 12px;
}

.company-add-edit-modal  .space {
    height: 10px;
}
.clients {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 8px;
    margin-bottom: 0;
    padding-left: 16px;
    border-bottom: 1px solid #F5F5F5;
}
.clients-list-container .divider {
    border-right: 1px solid #F5F5F5;
    width: 1px;
    margin-right: 24px;
}

.clients-list-container .clients-edit-name,
.clients-list-container .clients-edit-name > h1
{
    margin: 12px 0 -2px 1px;
    padding: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    height: 28px;
}

.search-input:hover:not([disabled]) {
    border-color: #40a9ff;
}

 .search-input {
    border-color: #d9d9d9;
    width: 174px;
}

.search-input::placeholder {
    color: #8c8c8c;
}

.clients-list-container .ant-dropdown {
    width: 185px;
}

.clients-list-container .ant-modal-content {
    width: 390px
}

.edit-input {
    height: 32px!important;
}

.deactivate-user .ant-modal-body {
    padding: 24px!important;
}

.deactivate-user .ant-modal-confirm-btns {
    margin-top: 18px!important;
}

.deactivate-user .ant-modal-confirm-content {
    margin-top: 9px!important;
}

.company-add-edit-modal .ant-modal-content,
.company-add-edit-modal .ant-modal-header {
    border-radius: 4px;
    padding: 0;
}
.company-add-edit-modal .ant-modal-content .ant-modal-close-x {
    height: 40px;
    line-height: 44px;
}

.company-add-edit-modal .company-title {
    margin-bottom: 8px;
}

.company-add-edit-modal .ant-modal-footer .ant-btn {
    width: 90px;
}

.clients-list-container > .empty-list-page {
    height: calc(100% - 144px);
}

.clients-list-container > .empty-search-result {
    height: calc(100% - 262px);
}

.clients-list-container .clients-edit-name > div > div {
    margin-right: 8px;
}
.search-container > div > .ant-input-search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn:hover > .anticon-search > svg {
    fill: #1890ff;
}

.search-container > div > .ant-input-search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn > .anticon-search > svg {
    fill: #262626;
}

.clients-list-container .remove-client {
    margin-right: 16px;
}

.clients-list-container .remove-client > button {
    height: 33px;
}

.clients-list-container .filters-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.app-header-dropdown .menu-item-wrapper {
    display: flex;
    align-items: center;
    height: 32px;
}
.app-header-dropdown {
    white-space: nowrap;
    width: max-content!important;
}

.app-header-dropdown .menu-item-wrapper:hover {
    background-color: #F5F5F5;
}

.app-header-dropdown .menu-item-wrapper > label {
    font-size: 16px;
    margin: 0 8px 0 12px;
}

.app-header-dropdown .add-to-group-button {
    height: 32px;
    width: calc(100% - 32px);
    margin: 4px 10px 0;
}

.app-header-dropdown .clients-search-wrapper > span > input {
    width: inherit;
}
.app-header-dropdown .clients-search-wrapper  {
    width: calc(100% - 32px) !important;
}

.remove-clients {
    max-width: 400px;
}

.remove-clients button {
    width: 90px;
}

.add-to-group-dropdown > div > ul > li > span {
    max-width: 248px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-button-wrapper > .underline {
    width: 100%;
}
.add-to-group-modal .ant-modal-header {
    padding: 8px 24px 8px 24px;
    height: 40px;
}

.add-to-group-modal .ant-modal-close {
    top: -6px
}

.add-to-group-modal .ant-modal-body {
    padding: 8px 10px 8px 10px;
}

.add-to-group-modal .ant-modal-body > div {
    height: 95px!important;
}

.add-to-group-modal .clients-search-wrapper {
    padding: 8px 2px 8px 2px;
}

.add-to-group-modal .ant-menu-item {
    height: 32px;
    line-height: 32px;
    margin-bottom: 0!important;
}

.add-to-group-modal .ant-modal-footer button {
    width: 90px;
}

.add-to-group-modal .ant-modal-title {
    color: #262626;
    font-weight: 600;
}

.add-to-group-modal .ant-menu-item:hover {
    background-color: #FAFAFA;
    color: #262626;
}
.add-to-group-modal .ant-menu-item-selected {
    background-color: #e6f7ff!important;
    color: #262626!important;
}

.add-to-group-modal .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    height: 32px;
}

.ant-menu-vertical .ant-menu-item {
    margin-top: 0;
    padding: 0 4px;
}

.add-to-group-modal .ant-input-wrapper .anticon {
    color: #262626;
}

.clients-list-container .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table-empty > .ant-table-container > .ant-table-body > table > .ant-table-tbody > .ant-table-placeholder {
    display: none;
}