.report-table-preview {
    width: 100%;
    height: 100%;
    padding: 16px 0 16px 18px
}

.report-table-preview .preview-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 16px;
}

.report-table-preview .empty-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}