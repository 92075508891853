
.template-summary {
    margin: 0 16px 16px 24px;
}
.template-position {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
}

.template-summary .project-view-edit-form .ant-form-item-label > label  {
    color: #000;
    font-weight: 600;
}
.template-summary .project-view-edit-form .form-item,
.template-summary .form-info .form-item
{
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 16px 0 0 0;
}

.template-summary .project-view-edit-form .form-item p {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.template-summary .form-header .template-name {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    cursor: pointer;
    width: fit-content;
}
.template-summary .form-header .template-name > .edit-icons {
    display: flex;
    align-items: center;
}
.template-summary .project-view-edit-form .description-container .subtitle .edit-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.template-summary .form-header .template-name > .edit-icons > .check-outlined,
.template-summary .project-view-edit-form .description-container .subtitle > .edit-icons > .check-outlined {
    color: #52C41A;
    margin: 0 16px 0 14px;
}
.template-summary .project-view-edit-form .template-estimate .estimate {
    margin: 0 14px;
}

.template-summary .form-header  .template-name > .edit-icons > span > svg,
.template-summary .form-header  .template-name > .edit-icons > .check-outlined > span > svg,
.template-summary .project-view-edit-form  .description-container .subtitle > .edit-icons > .check-outlined > span > svg,
.template-summary .project-view-edit-form .description-container .subtitle > .edit-icons > span > svg {
    width: 16px;
    height: 16px;
}

.template-summary .form-header .template-name > input {
    padding: 5px 12px;
    min-width: 308px;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
}

.template-summary .form-header .template-name > span > input {
    width: 308px;
}

.template-summary .form-header .template-name > textarea {
    resize: none;
    border: none;
    padding: 5px 12px;
}
.template-summary .form-header .template-name > span > textarea {
    width: 340px;
}
.template-summary .project-view-edit-form .description-container .subtitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
}

.template-summary .form-header .template-name > span > svg,
.template-summary .project-view-edit-form .description-container .subtitle > span > svg {
    display: none;
    width: 16px;
    height: 16px;
}
.template-summary .form-header .template-name > span > svg {
    margin-left: 14px;
}
.template-summary .project-view-edit-form .description-container .subtitle > span > svg {
    margin-left: 12px;
}

.template-summary .form-header .template-name:hover > span > svg,
.template-summary .project-view-edit-form .description-container:hover .subtitle > span > svg {
    display: inline-block;
}

.template-summary .project-view-edit-form .description-container
{
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    cursor: pointer;
}
.template-summary .project-view-edit-form .active {
    width: 500px;
}

.template-summary .project-view-edit-form .description-container .edit-icons-description {
    width: 100%;
}

.template-summary .project-view-edit-form .description-container .project-description,
.template-summary .project-view-edit-form .description-container .template-questionnaire
{
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    white-space: break-spaces;
}

.template-summary .project-view-edit-form .description-container > .text-area-container  {
    display: flex;
    border: none;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 0;
    min-width: 500px;
}

.template-summary .project-view-edit-form .description-container > .description-icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.template-summary .project-view-edit-form .title {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    margin-bottom: 18px;
    width: 333px;
}
.template-summary .project-view-edit-form .label-wrapper {
    display: flex;
    margin-bottom: 16px;
}
.template-summary .project-view-edit-form .template-label {
    background: #9254DE;
    right: 0;
    color: #fff;
    width: 93px;
    height: 28px;
    font-size: 16px;
    text-align: center;
}
.template-summary .project-view-edit-form .label-wrapper > span {
    display: flex;
    width: 100px;
    font-weight: 600;
    color: #000000;
    align-items: center;
}

.template-summary .check-outlined{
    color: #52C41A;
    margin: 0 16px 0 14px;
}

.template-summary .form-header .template-name > p {
    margin: 0;
    max-width: 310px;
}

.template-summary .estimate-ending {
    margin: 0 10px 0 5px;
}

.template-summary .edit-input + .estimate-ending {
    margin-left: 10px;
}

.template-summary .project-view-edit-form .template-estimate {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.template-summary .estimate-input {
    width: 100px;
}

.template-summary .template-container {
    display: flex;
    align-items: center;
}

.template-summary .template-details-subtitle {
    min-width: 100px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.template-summary .project-view-edit-form .edit-template > div {
    margin: 8px 0 0 16px;
}

.template-summary .edit-template {
    margin: 16px 0 0 8px;
}

.template-summary .project-view-edit-form .ant-form-item {
    margin-bottom: 16px;
}

.template-summary .clients-list-wrapper {
    margin-left: 100px;
}

.template-summary .clients-list > div {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
    color: #262626;
}

.template-summary .form-header .template-name > span > svg,
.template-summary .project-view-edit-form .description-container .subtitle > span > svg,
.template-summary .project-view-edit-form .template-estimate > span > svg{
    display: none;
    width: 16px;
    height: 16px;
}

.template-summary .edit-title {
    width: 367px;
}

.template-summary .separator {
    background-color: #f5f5f5;
    display: block;
    height: calc(100vh - 152px);
    overflow: hidden;
    position: absolute;
    width: 1px;
    gap: 8px;
}