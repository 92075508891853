.task-details-modal {
    width: 900px;
    height: 612px;
    display: flex;
    position: relative;
    align-items: stretch;
}

.task-details-modal > .task-description {
    width: 530px;
    padding: 10px 17px 16px 16px;
    border-right: 1px solid #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
}

.task-details-modal > .task-description > div:first-child {
    height: calc(100% - 106px);
    overflow: auto;
}

.task-details-modal .task-description .task-name textarea {
    width: 100% !important;
}

.task-details-modal .task-description .task-name .title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-right: 15px;
    max-width: calc(100% - 35px);
}

.task-details-modal .description-container {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-top: 18px;
    width: 100%;
}

.task-details-modal .description-container > div:first-child {
    margin-bottom: 8px;
}

.task-details-modal .edit-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.task-details-modal .description {
    color: #595959;
    padding-left: 0;
    padding-top: 6px;
    resize: none;
    width: 496px;
    border: 1px solid white;
    overflow: auto!important;
}

.task-details-modal .text-area-container .description {
    margin-left: 0;
    padding: 6px 8px 8px 8px;
    max-height: 400px!important;
    border: 1px solid #1890FF;
}

.task-details-modal .description:focus-visible {
    border: 1px solid #1890FF;
}

.task-details-modal .task-options {
    width: 370px;
    padding: 10px 16px 16px;
    overflow: auto;
    flex: 1;
}

.task-details-modal .task-options .header {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 18px;
}

.task-details-modal .input-wrapper {
    padding: 8px 12px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #BFBFBF;
}

.task-details-modal .input-wrapper > input {
    height: 22px;
    background-color: #FFFFFF;
}

.task-details-modal .input-wrapper > .icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.task-details-modal .input-wrapper > .icon-container > span {
    margin-right: 24px;
    width: 16px;
    height: 16px;
}
.task-details-modal .input-wrapper > .icon-container > span:nth-child(3) {
    margin-right: 16px;
}

.task-details-modal .input-wrapper > .ant-input:focus, .ant-input-focused {
    box-shadow: none;
}
.task-details-modal .input-wrapper > .ant-input {
    border: none;
    padding-left: 0;
}

.task-details-modal .stats-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0;
    margin-bottom: 16px;
    margin-left: 0;
}

.task-details-modal .stats-container > div {
    margin: 0;
    display: flex;
}

.task-details-modal .stats-container > .subtitle,
.task-details-modal .dependency-task > .subtitle
{
    margin: 0;
    width: 100px;
    font-weight: 600;
    line-height: 22px;
    color: #000000;
}
.task-details-modal .stats-container > .avatar-container {
    display: flex;
    align-items: center;
    width: 200px;
}

.task-details-modal .stats-container > .avatar-container .assignee {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.task-details-modal  .stats-container > .avatar-container > div > .not-assignee {
    width: 24px;
    height: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    color: #1890FF;
    border: 1px solid #1890FF;
}
.task-details-modal  .stats-container .assignee {
    height: 30px;
}
.task-details-modal  .stats-container > .avatar-container > div > .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: unset;
}
.task-details-modal  .stats-container > .avatar-container .assignee > .select-assignee {
    width: 185px;
    color: #000000;
}
.task-details-modal .stats-container > .avatar-container .assignee > .select-assignee .close-icon {
    padding-right: 16px;
    color: #262626;
}

.task-details-modal .stats-container > .avatar-container .assignee > .assignee-wrapper > .anticon-caret-down {
    display: none;
}

.task-details-modal  .stats-container > .avatar-container .assignee > .select-assignee + .not-assignee {
    margin-left: 8px;
}

.task-details-modal  .stats-container > .avatar-container .assignee > .select-assignee:last-child {
    margin-left: 8px;
}

.task-details-modal .stats-container > .avatar-container .assignee > .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 3px 11px;
    height: 38px;
}
.task-details-modal  .stats-container > .avatar-container .assignee > .assignee-wrapper {
    display: flex;
    align-items: center;
}
.task-details-modal  .stats-container > .avatar-container .assignee > .assignee-wrapper:hover > .ant-tooltip-open + .anticon-caret-down {
    display: block;
}
.task-details-modal  .stats-container > .avatar-container .assignee > .assignee-wrapper:not(:first-child) > .assignee-image  {
    margin-left: 8px;
}
.task-details-modal  .stats-container > .avatar-container .assignee > .assignee-wrapper > .dropdown-icon {
    padding-left: 4px;
}

.task-details-modal .stats-container > .avatar-container .ant-avatar-square .ant-avatar-string {
    transform: none !important;
    position: unset;
    display: inline-block;
    line-height: unset;
}

.task-details-modal .stats-container > .avatar-container .created-by {
    margin: 0 16px 0 0;
    display: flex;
    max-width: 240px;
    font-weight: 400;
    line-height: 14px;
    color: #000000;
    padding-left: 8px;

}

.task-details-modal .date-picker > .subtitle > .date-label {
    margin-left: 8px;
}

.task-edit-popup .ant-modal-body {
    padding: 0;
}

.task-details-modal .check-outlined {
    color: #52C41A;
    margin: 0 14px;
}

.task-details-modal .task-name {
    display: flex;
    align-items: center;
}

.task-details-modal .task-name > .edit-area {
    width: calc(100% - 35px);
}


.task-details-modal .task-name > span > svg,
.task-details-modal .description-container .edit-icon svg {
    display: none;
}

.task-details-modal .task-name:hover > span > svg,
.task-details-modal .description-container .subtitle:hover > .edit-icon span > svg,
.task-details-modal .description-container:hover .edit-icon svg {
    display: inline-block;
}

.task-details-modal .description-container .subtitle {
    display: flex;
    align-items: center;
}

.task-details-modal .description-container .subtitle > .edit-icon {
    margin-left: 13px;
}

.task-details-modal .task-description-layout {
    display: flex;
    align-items: stretch;
    margin-bottom: 8px;
}

.task-details-modal .description-text {
    white-space: break-spaces;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #595959;
}

.task-details-modal .edit-icons-description {
    width: 100%;
}

.task-details-modal .description-click-container {
    height: 100%;
}

.task-details-modal .stats-container.duration > .ant-picker-range {
    width: 238px;
}

.task-details-modal .duration-container > div {
    cursor: pointer;
}

.task-details-modal .duration-container > span > svg {
    display: none;
    width: 12.5px;
    height: 12.5px;
}

.task-details-modal .duration-container {
    height: 30px;
}

.task-details-modal .duration-container:hover > span > svg {
    display: inline-block;
    margin-left: 6px;
}

.task-details-modal .dependency-task {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 0;
    margin-bottom: 16px;
    margin-left: 0;
}

.task-details-modal .dependency-task > .see-task-container > .see-task {
    padding: 1px 8px;
    width: 92px;
    height: 24px;
    align-items: center;
    display: flex;
    gap: 8px;
}

.task-details-modal .dependency-task > .see-task-container > .see-task > .anticon + span, .ant-btn > span + .anticon {
        margin-left: 0;
}


.task-details-modal .dependency-task:hover .see-task-container > button {
    height: 22px;
    padding-top: 0;
}

.task-details-modal .task-select ~ div > div > div > div > div:first-child {
        border-bottom: 1px solid #F1F1F1;
}

.task-details-modal .dependency-task-container > span {
        margin-right: 5px;
}

.task-details-modal .dependency-task-container {
        cursor: pointer;
        line-height: 24px;
}
.task-details-modal .dependency-task > .subtitle {
        margin-right: 22px;
        width: 78px;
}

.task-details-modal .see-task-container {
        margin-right: 0;
        display: none;
}

.task-details-modal .dependency-task-container > span > span {
    margin-right: 16px;
}
