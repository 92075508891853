.users-empty {
    width: 100%;
    height: calc(100% - 228px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.users-empty img {
    width: 158px;
    height: 140px;
}

.users-empty > div:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #262626;
    margin-top: 10px;
}

.users-empty > div:nth-child(3) {
    height: 58px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8C8C8C;
}

.users-empty > div:nth-child(4) {
    margin-top: 4px;
}

.users-empty > div:nth-child(4) .add-user-button {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    display: flex;
    cursor: pointer;
    width: 162px;
    height: 40px;
    user-select: none;
    align-items: center;
    justify-content: center;
}

.users-empty > div:nth-child(4) .add-user-button:hover {
    color: #40a9ff;
}

.users-empty > div:nth-child(4) .add-user-button:active {
    border: 1px solid #096dd9;
    color: #096dd9;
}