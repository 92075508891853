.sidebar-wrapper {
    padding: 5px 0 5px 26px;
    height: 100%;
}

.sidebar-wrapper > .sidebar-header {
    display: flex;
    justify-content: space-between;
    height: 31px;
}
.ant-dropdown > .task-dropdown > .icon-dropdown > .ant-dropdown-menu-item-icon {
    margin-right: 10px;
}
.task-dropdown > .icon-dropdown {
    padding: 5px 31px 5px 14.5px;
}

.sidebar-wrapper > .sidebar-header > .icon-container > span,
.sidebar-wrapper > .sidebar-header > .icon-container > .ant-dropdown-trigger > span
{
    margin-right: 17px;
    width: 16px;
    height: 16px;
    color: #262626;
}
.sidebar-wrapper > .sidebar-header > .icon-container > span:last-child {
    margin-right: 10px;
}
.sidebar-wrapper > .sidebar-header > .prefix {
    line-height: 22px;
    display: flex;
    align-items: center;
}
.sidebar-wrapper > .sidebar-header > .prefix > span {
    color: #8C8C8C;
    margin-right: 8px;
}

.sidebar-wrapper > .content-wrapper {
    overflow: auto;
}

.sidebar-wrapper > .content-wrapper-comment {
    overflow: auto;
    height: calc(100% - 136px);
}

.sidebar-wrapper .task-name {
    margin: 20px 0 -3px 0;
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    cursor: pointer;
    width: fit-content;
}
.sidebar-wrapper .task-name > .edit-icons {
    display: flex;
    align-items: center;
}

.sidebar-wrapper .duration-container > div {
    margin: 0;
    cursor: pointer;
}

.sidebar-wrapper .task-name > .edit-icons > .check-outlined,
.sidebar-wrapper .duration-container > .edit-icons > .check-outlined,
.sidebar-wrapper .description-container > .text-area-container > .edit-icons > .check-outlined {
    color: #52C41A;
    margin: 0 14px;
}
.sidebar-wrapper .task-name > .edit-icons > span > svg,
.sidebar-wrapper .task-name > .edit-icons > .check-outlined > span > svg,
.sidebar-wrapper .description-container > .text-area-container > .edit-icons > .check-outlined > span > svg,
.sidebar-wrapper .description-container > .text-area-container > .edit-icons > span > svg {
    width: 16px;
    height: 16px;
}

.sidebar-wrapper .task-name > input {
    padding: 5px 12px;
    min-width: 308px;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
}

.sidebar-wrapper .task-name > textarea {
    resize: none;
    border: none;
    padding: 5px 12px;
}

.sidebar-wrapper .task-name > p {
    margin: 0;
    max-width: 380px;
}

.sidebar-wrapper .task-name > span > textarea {
    width: 340px;
}

.sidebar-wrapper .task-name > span > svg,
.sidebar-wrapper .duration-container > span > svg,
.sidebar-wrapper .description-container > span > svg {
    display: none;
    width: 12.5px;
    height: 12.5px;
}
.sidebar-wrapper .task-name > span > svg {
    margin-left: 14px;
}
.sidebar-wrapper .description-container > span > svg {
    margin: 6px 0 0 12px;
}

.sidebar-wrapper .task-name:hover > span > svg,
.sidebar-wrapper .duration-container:hover > span > svg,
.sidebar-wrapper .description-container:hover > span > svg {
    display: inline-block;
}

.sidebar-wrapper .duration-container:hover > span > svg {
    margin-left: 6px;
}
.sidebar-wrapper .description-container:hover > .description {
    width: 266px;
}

.sidebar-wrapper .stats-container {
    margin: 8px 0 ;
    align-items: center;
}

.sidebar-wrapper .stats-container .ant-picker-range {
    margin: 0;
    width: 280px;
}
.sidebar-wrapper .stats-container.duration-container {
    height: 30px;
}
.sidebar-wrapper .status {
    margin: 0;
}
.sidebar-wrapper .stats-container > .subtitle {
    margin: 0;
    width: 100px;
    font-weight: 600;
    line-height: 22px;
}

.sidebar-wrapper .description-container {
    justify-content: flex-start;
    align-items: flex-start;
}
.sidebar-wrapper .description-container > .text-area-container  {
    display: flex;
    border: none;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 0;
    width: 287px;

}
.sidebar-wrapper .description-container > .text-area-container > .edit-icons {
    justify-content: flex-end;
    display: flex;
    margin-bottom: 5px;
}
.sidebar-wrapper .description-container > .text-area-container > .description {
    margin-left: 8px;
    height: 152px;
    padding: 1px 8px;
}
.sidebar-wrapper .description-container > .description-icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sidebar-wrapper .description-container > .description-icon > .subtitle {
    margin: 0;
    font-weight: 600;
    line-height: 22px;
}

.sidebar-wrapper .description-container > .subtitle {
    margin-top: 4px;
}
.sidebar-wrapper .data-picker {
    width: 270px;
}
.sidebar-wrapper .data-picker > .subtitle {
    padding-right: 8px;
    width: 82px;
}

.sidebar-wrapper .data-picker.duration > .subtitle {
    width: 122px;
}

.sidebar-wrapper .data-picker > .checkbox {
    margin-right: 33px;
}

.sidebar-wrapper .data-picker.duration > .ant-picker {
    margin-left: 24px;
}

.sidebar-wrapper .data-picker > .ant-picker {
    width: 100%;
    margin-right: 0;
}
.sidebar-wrapper .stats-container > .description {
    color: #595959;
    margin-left: 8px;
    margin-top: 6px;
    padding: 0;
    width: 266px;
    resize: none;
    border: none;
    height: auto;
}
.sidebar-wrapper .stats-container > .description:focus-visible {
    border: 1px solid #1890FF;
}

.sidebar-wrapper > .input-wrapper {
    padding: 8px 12px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #BFBFBF;
    margin-top: 15px;
}
.sidebar-wrapper > .input-wrapper > input {
    height: 22px;
}
.sidebar-wrapper  > .input-wrapper > .icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sidebar-wrapper > .input-wrapper > .icon-container > span {
    margin-right: 24px;
    width: 16px;
    height: 16px;
}
.sidebar-wrapper > .input-wrapper > .icon-container > span:nth-child(3) {
    margin-right: 16px;
}

.sidebar-wrapper > .input-wrapper > .ant-input:focus, .ant-input-focused {
    box-shadow: none;
}
.sidebar-wrapper  > .input-wrapper > .ant-input {
    border: none;
    padding-left: 0;
}

.dropdown-assignee {
    max-width: 320px;
}

.dropdown-assignee > .ant-dropdown-menu {
    overflow: auto;
    max-height: 277px;
}

.task-details-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.task-details-title .prefix {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #262626;
}

.task-details-title .prefix span:first-child {
    color: #8C8C8C;
}

.task-details-title .prefix span {
    margin-right: 8px;
}

.task-details-title a.ant-dropdown-trigger {
    color: #262626;
}

.task-edit-popup .ant-modal-header {
    height: 32px;
    padding: 0 27px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.task-edit-popup .ant-modal-header .ant-modal-title {
    width: 100%;
}

.task-edit-popup .ant-modal-header .ant-modal-title .copy-link,
.sidebar-header .icon-container .copy-link {
    width: 32px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}

.task-edit-popup .ant-modal-header .ant-modal-title .copy-link-check,
.sidebar-header .icon-container .copy-link-check {
    background-color: #F5F5F5;
}

.sidebar-header .icon-container,
.task-details-title .icon-container {
    display: flex;
    align-items: center;
}

.copied-message .ant-tooltip-inner {
    background: #F6FFED;
    border: 1px solid #B7EB8F;
    border-radius: 2px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.copied-message-modal .ant-tooltip-inner {
    position: relative;
    top: -6px;
    right: 15px;
}

.copied-message-sidebar .ant-tooltip-inner {
    margin-right: 15px;
    position: relative;
    top: -11px;
}

.task-assignee-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sidebar-wrapper .comments-section {
    margin-top: 8px;
}

.sidebar-wrapper textarea.ant-input {
    min-height: unset;
}

.content-wrapper .dependency-task > .see-task-container > .see-task {
    padding: 1px 8px;
    width: 92px;
    height: 24px;
    align-items: center;
    display: flex;
    gap: 8px;
}

.content-wrapper .dependency-task > .see-task-container > .see-task > .anticon + span, .ant-btn > span + .anticon {
    margin-left: 0;
}


.content-wrapper .dependency-task:hover .see-task-container > button {
    height: 22px;
    padding-top: 0;
}

.task-select ~ div > div > div > div > div:first-child:has(div > .none) {
    border-bottom: 1px solid #F1F1F1;
}
.sidebar-wrapper .stats-container .dependency-task-container > span.anticon {
    margin-right: 5px;
}
.sidebar-wrapper .stats-container .dependency-task-container > span  > .dependency-name {
    margin-right: 16px;
}
.sidebar-wrapper .dependency-task-container {
    line-height: 24px;
    margin: 0;
    cursor: pointer;
}
.sidebar-wrapper .dependency-task {
    display: flex;
    align-items: flex-start;
}
.sidebar-wrapper .dependency-task > .subtitle {
    margin-right: 22px;
    width: 78px;
}

.sidebar-wrapper  .stats-container > .see-task-container {
    margin-right: 20px;
}

.sidebar-wrapper .see-task-container {
    display: none;
}