.searchable-select-popup .options-search {
    margin: 0 8px 10px 8px;
    padding-right: 10px;
}

.searchable-select-popup .options-search input {
    height: 24px;
}

.searchable-select-popup .options-search button {
    height: 24px;
    width: 24px;
}