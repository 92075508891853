
.master-container {
    padding: 22px 16px 16px 16px;
    width: 90%;
}
.master-container > .master-title {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    line-height: 28px;
}

.master-container > .master-description {
    width: 498px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: 18px;
}
.search-input .ant-input-group-addon {
    background-color: #F5F5F5;
    opacity: 0.6;
}
.last-image {
    margin-right: 0;
}

.not-first-image {
    margin-right: 8px;
}

.master-container .table-wrapper .owner-image {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    background-color: gray;
}

.master-container .table-wrapper .ant-table-tbody > tr > td
{
    padding: 6px 8px;
    height: 38px;
}

.master-container .table-wrapper .ant-table-thead > tr > th {
    padding: 7px 8px;
    height: 38px;
}

.master-container .table-wrapper .ant-checkbox .ant-checkbox-inner {
    width: 14px;
    height: 14px;
    border: 1.27px solid #1890FF;
}

.master-container .table-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c6e4ff;
    border: 1.27px solid #1890FF;
    width: 14px;
    height: 14px;
}
.master-container .table-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid #1890FF;
    position: absolute;
    display: table;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
    left: 2px;
}

.master-container .table-wrapper .table-action .scanners-image {
    display: flex;
    align-items: center;
}
.master-container .table-wrapper .table-action .scanners-image > p {
    margin: 0;
}
.master-container .table-wrapper .table-action .scanners-image > img {
    margin-right: 10px;
}

.master-container .command-buttons .add {
    width: 81px;
}

.master-container .search-container {
    margin-top: 16px;
}

.master-container >.filter {
    border: 1px dashed #D9D9D9;
    height: 32px;
    width: 90px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 8px 0;
    cursor: not-allowed;
}

.master-container > .filter > div > .plus {
    margin: 0 4px 0 10px;
}

.master-container > .filter > div {
    line-height: 22px;
    font-weight: 400;
    color: #262626;
}