.document-details {
    width: 431px;
    padding-left: 16px;
    max-height: 100%;
    overflow: auto;
}

.document-details .document-details-header {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    color: #262626;
}

.document-details .document-details-header .document-commands {
    display: flex;
    cursor: pointer;
}

.document-details .document-details-header .document-commands > div {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.document-details .document-details-body {
    margin-top: 22px;
}

.document-details .document-details-body .document-row {
    margin-bottom: 9px;
    display: flex;
}

.document-details .document-details-body .document-row > div:last-child {
    display: flex;
    align-items: flex-start;
}

.document-details .document-details-body .document-row > div:first-child {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.document-details .document-details-body .document-row:last-child > div{
    align-items: center;
}

.document-details .document-details-body .document-row:first-child > div:last-child {
    align-items: center;
    display: flex;
    width: 100%;
}

.document-details .document-details-body .document-row:nth-child(2) > div:last-child {
    color: #595959;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.document-details .document-details-body .document-row .document-download {
    margin-left: 5px;
    margin-right: 8px;
    max-width: 275px;
}

.document-details .document-details-body .document-row .user-name {
    margin-left: 8px;
}

.document-edit {
    width: 295px;
}

.document-details .document-row:first-child {
    min-height: 23px;
}

.document-details .document-name-row .document-edit-button {
    display: none;
    background-color: #FFFFFF;
}

.document-details .document-name-row .document-edit-button:hover {
    background-color: #ffffff;
}

.document-details .document-name-row .show-icon {
    background-color: #F5F5F5;
}

.document-details .document-name-row .show-icon:hover {
    background-color: #ffffff;
}

.document-details .document-name-row .show-icon {
    display: block;
}

.document-details .document-name-row:hover .document-edit-button {
    display: block;
}

.document-edit-popover .ant-popover-title {
    height: 32px;
    color: #262626;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.document-edit-popover .document-edit .document-edit-body {
    margin: 8px 24px;
}

.document-edit-popover .document-edit-footer {
    height: 44px;
    border-top: 1px solid #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 16px;
}

.document-edit-popover .document-edit-footer button {
    width: 58px;
    padding: 1px 8px;
    margin-right: 8px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.document-edit-popover .document-edit-footer button:last-child {
    margin-right: 0;
}

.document-edit-popover .form-row {
    margin-bottom: 10px;
}

.document-edit-popover .document-edit .form-label {
    margin-bottom: 8px;
    color: #262626;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.document-edit-popover .document-edit .edit-textbox {
    height: 24px;
    color: #262626;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 0 8px 2px 8px;
}

.document-edit-popover .ant-popover-inner-content {
    padding: 0;
}

.document-details .document-details-body .description-container > .text-area-container  {
    display: flex;
    border: none;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 0;
    width: 287px;

}
.document-details .document-details-body .description-container > .text-area-container > .edit-icons {
    justify-content: flex-end;
    display: flex;
    margin-bottom: 5px;
}
.document-details .document-details-body .description-container > .text-area-container > .description {
    margin-left: 8px;
    height: 152px;
    padding: 1px 8px;
}
.document-details .document-details-body .description-container > .description-icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.document-details .document-details-body .description-container {
    justify-content: flex-start;
    align-items: flex-start;
}

.document-details .document-details-body .description-container:hover > .description {
    width: 266px;
}

.document-details .document-details-body .description-container:hover > span > svg {
    display: inline-block;
}

.document-details .document-details-body .description-container > span > svg {
    margin: 6px 0 0 12px;
}

.document-details .document-details-body .description-container > span > svg {
    display: none;
    width: 12.5px;
    height: 12.5px;
}

.document-details .document-details-body .text-area-container > .edit-icons > .check-outlined {
    color: #52C41A;
    margin: 0 14px;
}

.document-details .document-details-body .text-area-container > .edit-icons > .check-outlined > span > svg,
.document-details .document-details-body .text-area-container > .edit-icons > span > svg {
    width: 16px;
    height: 16px;
}

.document-details .document-details-body .description-container > .description {
    color: #595959;
    margin-left: 8px;
    padding: 0;
    resize: none;
    border: none;
    height: 32px;
}
.document-details .document-details-body .description-container > .description {
    width: 266px;
    height: auto;
}

.document-details .document-details-body .description:focus-visible {
    border: 1px solid #1890FF;
}

.document-details .document-details-body .text-area-container > .edit-icons > .check-outlined {
    color: #52C41A;
    margin: 0 14px;
}

.document-details .document-details-body .description-container .text-area-container {
    display: flex;
    border: none;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 0;
    width: 287px;
}

.document-details .document-details-body .text-area-container .edit-icons {
    justify-content: flex-end;
    display: flex;
    margin-bottom: 5px;
}

.document-details .document-details-body .description {
    margin-left: 8px;
    padding: 1px 8px;
}
