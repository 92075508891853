.overview-block {
    display: flex;
    margin-left: 8px;
}

.overview-block > .card-description {
    line-height: 20px;
}
.overview-block  > .card-block {
    margin-top: 16px;
    margin-right: 24px;
    height: 347px;
}
.overview-block  > .card-block > .ant-card-body {
    width: 243px;
    height: 347px;
}
.overview-block  > .card-block > .switch-block {
    margin-bottom: 24px;
}
.overview-block > div > div > .card-setting {
    border: 1px solid #D9D9D9;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    width: 195px;
}

.overview-block > div > div > .card-setting.disabled {
    background-color: #F5F5F5;
    color: #BFBFBF;
    cursor: not-allowed;
}

.overview-block > div > div > .card-setting >.setting {
    padding-left: 9.37px;
}

.overview-block  > div > div > .card-setting:not(.disabled) >.setting {
    color: #262626
}

.overview-block  > div > div > .ant-card-meta {
    display: flex;
    justify-content: center;
    margin: 0 0 16px 0;
}
.ant-card-actions > li {
    margin:4px 0;
}

.overview-block  > .card-block > div > .switch-block {
    display: flex;
    margin: -8px -8px 0 0;
    justify-content: flex-end;
}

.overview-block  > .card-block > div > .image {
    margin: 38px auto 8px auto;
    text-align: center;
}

.overview-block > div > div > .ant-card-meta > div > .ant-card-meta-title {
    color: #2E2C34;
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
}

.overview-block > div > div > .ant-card-meta > div > .ant-card-meta-description {
    color: #84818A;
    font-weight: 500;
    line-height: 20px;
}

.overview-block > div > div > div > .ant-tag {
    border-radius: 4px;
    border-color: #EBEAED;
    color: #84818A;
    background-color: #FBFAFC;
    cursor: not-allowed;
    padding: 3px 9px;
    width: max-content;
    margin-right: 0;
}

.overview-block > div > div > .tags-wrapper {
    min-width: 195px;
    display: flex;
    justify-content: space-between;
}