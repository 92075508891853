.projects-templates-list {
    margin-left: 16px;
}

.projects-templates-list .empty-template-page {
    height: calc(100vh - 244px);

}

.projects-templates-list .empty-project-template-page {
    height: calc(100vh - 224px);
}

.projects-templates-list > .arrow-wrapper {
    margin: 16px 0 26px;
}

.projects-templates-list > .arrow-wrapper > .projects-templates {
    display: flex;
    color: #262626;
    align-items: flex-end;
    margin-left: 8px;
}
.projects-templates-list > .arrow-wrapper > .arrow {
    font-size: 17px;
    width: 24px;
    height: 24px;
    display: flex;
    color: rgb(38, 38, 38);
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.projects-templates-list > .arrow-wrapper {
    display: flex;
}

.projects-templates-list > h3 {
    color: #000;
    margin: 26px 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.projects-templates-list > .templates-wrapper {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 23px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-bottom: 23px;
}

.projects-templates-list > .templates-wrapper > .project-template-slide-item {
    margin: unset;
}

.projects-templates-list .commands-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 16px 16px 0;
}

.projects-templates-list .commands-buttons > div:first-child{
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
}


.projects-templates-list .add-template {
    width: 81px;
}

.projects-templates-list .view-banner-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    border-radius: 4px;
    background: #FFCCC7;
    margin: 0 16px 8px 0;
    align-items: center;
}

.projects-templates-list .view-banner {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.projects-templates-list .view-banner .selected-client {
    color: #1890FF;
    cursor: pointer;
    margin-left: 8px;
    display: flex;
}

.projects-templates-list .view-as {
    margin-right: 16px;
}

.template-options-search {
    height: 40px!important;
    padding: 8px 10px;
}

.template-options-search > span > input {
    height: 24px;
}

.template-options-search > span > span > button{
    height: 24px;
    width: 24px;
}

.template-options-search + div {
    max-height: 300px;
    overflow: auto;
}

li:has(span > .last-group) {
    border-bottom: 1px solid #F1F1F1;
}

.ant-dropdown {
    border-radius: 2px;
    background:  #FFF;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.template-dropdown .ant-dropdown-arrow {
    left: calc(50% - 8px)!important;
    top: -6px!important;
}

.projects-templates-list .caret-icon {
    margin-left: 8px;
}