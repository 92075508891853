.devices {
    padding: 20px 16px 30px 16px;
    height: 100%;
}

.devices .table-container {
    height: 100%;
}

.devices > .empty-list-page-wrapper {
    height: calc(100% - 234px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.devices > .empty-list-page-wrapper > .empty-list-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.devices > .empty-list-page-wrapper > .empty-list-page > .clients-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.devices > .empty-list-page-wrapper > .empty-list-page > .clients-empty img {
    width: 158px;
    height: 140px;
}

.devices > .empty-list-page-wrapper > .empty-list-page > .clients-empty > div:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #262626;
    margin-top: 10px;
}

.devices > .empty-list-page-wrapper > .empty-list-page > .clients-empty > div:nth-child(3) {
    height: 58px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8C8C8C;
}

.devices > .empty-list-page-wrapper > .empty-list-page > .clients-empty > div:nth-child(4) {
    margin-top: 4px;
}

.devices > .empty-list-page-wrapper > .empty-list-page > .clients-empty > div:nth-child(4) .add-device-button-disabled {
    gap: 8px;
    width: 162px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: center;
}

.devices .filter {
    border: 1px dashed #D9D9D9;
    height: 32px;
    width: 90px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 8px 0;
    cursor: not-allowed;
}

.devices .filter > div > .plus {
    margin: 0 4px 0 10px;
}

.devices > .filter > div {
    line-height: 22px;
    font-weight: 400;
    color: #262626;
}

.devices > .search-container > .command-buttons > button:nth-child(4) {
    width: 81px;
    margin-right: 16px;
}

.devices > h1 {
    position: relative;
    left: -2px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
}

.devices .ant-statistic-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8C8C8C;
}

.devices > .empty-container > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table-empty > .ant-table-container > .ant-table-body > table > .ant-table-tbody > .ant-table-placeholder {
    display: none;
}

.devices .search-container > .command-buttons > button:nth-child(2) {
    width: 32px;
    margin-right: 8px;
}