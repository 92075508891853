.client-page {
    height: calc(100% - 18px);
}
.client-page .client-summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #262626;
    margin: 18px 16px 28px 15px;
}

.client-page .client-summary-header div {
    display: flex;
    align-items: center;
}

.client-page .client-summary-header .client-name {
    margin-left: 11px;
}

.client-page .client-summary {
    margin: 0 16px 16px 16px;
    height: unset;
}

.client-page .client-summary h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin: 0 0 10px;
}

.client-page .client-summary .form-header {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    height: 28px;
}

.client-page .client-summary .client-summary-view > div {
    position: relative;
    padding-right: 16px;
}

.client-page .client-summary .client-view-edit-form {
    width: 400px;
}
.client-page .client-summary .separator {
    width: 1px;
    display: block;
    background-color: #F5F5F5;
    overflow: hidden;
    height: calc(100vh - 152px);
    top: -54px;
    position: absolute;

}
.client-page .client-summary .separator-header {
    width: 100%;
    height: 1px;
    margin-bottom: 26px;
    display: flex;
    background-color: #F5F5F5;
    overflow: hidden;
}

.client-page .client-summary .client-view-edit-form .ant-form-item-label > label  {
    color: #000;
    font-weight: 600;
}
.client-page .client-summary .client-view-edit-form .form-item,
.client-page .client-summary .form-info .form-item
{
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 16px 0 0 0;
}
.client-page .client-summary .client-view-edit-form .form-item .visit-space {
    padding: 8px 16px;
    width: 199px;
    height: 40px;
    align-items: center;
    display: flex;
    gap: 8px;
}
.client-page .client-summary .client-view-edit-form .upload-company-logo .profile-picture {
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #262626;
}
.client-page .client-summary .client-view-edit-form .form-item p {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.client-page .client-summary .form-header .client-name {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    cursor: pointer;
    width: fit-content;
}

.client-page .client-summary .form-header .client-name > .edit-icons {
    display: flex;
    align-items: center;
}
.client-page .client-summary .client-view-edit-form .description-container .subtitle .edit-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.client-page .client-summary .form-header .client-name > .edit-icons > .check-outlined,
.client-page .client-summary .client-view-edit-form .description-container .subtitle > .edit-icons > .check-outlined {
    color: #52C41A;
    margin: 0 16px 0 14px;
}
.client-page .client-summary .form-header  .client-name > .edit-icons > span > svg,
.client-page .client-summary .form-header  .client-name > .edit-icons > .check-outlined > span > svg,
.client-page .client-summary .client-view-edit-form  .description-container .subtitle > .edit-icons > .check-outlined > span > svg,
.client-page .client-summary .client-view-edit-form .description-container .subtitle > .edit-icons > span > svg {
    width: 16px;
    height: 16px;
}

.client-page .client-summary .form-header .client-name > input {
    padding: 5px 12px;
    min-width: 308px;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
}

.client-page .client-summary .form-header .client-name > span > input {
    width: 308px;
}

.client-page .client-summary .form-header .client-name > textarea {
    resize: none;
    border: none;
    padding: 5px 12px;
}

.client-page .client-summary .form-header .client-name > p {
    margin: 0;
    max-width: 380px;
}

.client-page .client-summary .form-header .client-name > span > textarea {
    width: 340px;
}
.client-page .client-summary .client-view-edit-form .description-container .subtitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.client-page .client-summary .form-header .client-name > span > svg,
.client-page .client-summary .label-wrapper > .group-wrapper .groups-edit > span > svg,
.client-page .client-summary .client-view-edit-form .description-container .subtitle > span > svg {
    display: none;
    width: 16px;
    height: 16px;
}
.client-page .client-summary .form-header .client-name > span > svg {
    margin-left: 14px;
}
.client-page .client-summary .client-view-edit-form .description-container .subtitle > span > svg {
    margin-left: 12px;
}

.client-page .client-summary .form-header .client-name:hover > span > svg,
.client-page .client-summary .label-wrapper:hover .groups-edit > span > svg,
.client-page .client-summary .client-view-edit-form .description-container:hover .subtitle > span > svg {
    display: inline-block;
}

.client-page .client-summary .client-view-edit-form .stats-container {
    margin: 0;
    align-items: center;
}

.client-page .client-summary .client-view-edit-form .description-container
{
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    cursor: pointer;
}
.client-page .client-summary .client-view-edit-form .active {
    width: 500px;
}

.client-page .client-summary .client-view-edit-form .description-container .edit-icons-description {
    width: 100%;
}

.client-page .client-summary .client-view-edit-form .description-container .description {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    white-space: break-spaces;
}

.client-page .client-summary .client-view-edit-form .description-container > .text-area-container  {
    display: flex;
    border: none;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 0;
    min-width: 500px;
}

.client-page .client-summary .client-view-edit-form .description-container > .description-icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.client-page .client-summary .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 228px;
    padding: 0 11px;
}

.client-page .client-summary .lead-item {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.client-page .client-summary .lead-item .profile-pic {
    margin-right: 4px;
}

.client-page .client-summary .client-summary-view {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
}

.client-page .client-summary .client-summary-right-column {
    margin-top: -48px;
    width: 400px;
    padding: 16px 0 16px 16px;

}

.client-page .client-summary .client-summary-right-column .title {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 18px;
    width: 333px;
}

.client-page .client-summary .form-info > div {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.client-page .client-summary .form-info > div:last-child {
    color: #1890FF;
    margin-top: 8px;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
}

.client-page .ant-modal-close-x {
    height: 16px;
    width: auto;
    display: inline;
    position: relative;
    top: -10px;
    margin-right: 24px;
}

.client-page .client-summary .label-wrapper {
    display: flex;
    height: 28px;
}
.client-page .client-summary .group {
    height: unset;
    margin-bottom: 8px!important;
}
.client-page .client-summary .label-wrapper .label-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #262626;
}

.client-page .client-summary .label-wrapper .date-picker {
    width: 170px;
    align-items: center;
    padding: 5px 12px;
}

.client-page .client-summary .label-wrapper > .group-wrapper > div:first-child {
    display: flex;
    flex-wrap: wrap;
}

.client-page .client-summary .label-wrapper > .group-wrapper .icon-folder {
    margin-right: 4px;
}

.client-page .client-summary .label-wrapper > .group-wrapper .groups-edit {
    margin-left: 4px;
}

.client-page .client-summary .label-wrapper > .group-wrapper .icon-folder svg {
    width: 16px;
    height: 16px;
}

.client-page .client-summary .label-wrapper .belongs-group {
    align-items: start;
}
.client-page .client-summary .label-wrapper .group-wrapper {
    display: flex;
    flex-wrap: wrap;
    color: #000000;
    width: 100%;
}
.client-page .client-summary .label-wrapper p {
    margin-right: 16px;
}

.client-page .client-summary .left-menu {
    display: block;
}

.client-page .client-summary .left-menu .date-picker {
    margin-top: 8px;
}
.client-page .client-summary .left-menu > span {
    display: flex;
    width: 100px;
    align-items: center;
}
.client-page .client-summary .left-menu-wrapper {
    margin-top: 16px;
}

.client-page .client-summary .left-menu-wrapper .left-select {
    margin-top: 8px;
}

.client-page .client-summary .label-wrapper > span {
    display: flex;
    min-width: 150px;
    font-weight: 600;
    color: #000000;
    align-items: center;
}

.client-page .client-summary .label-wrapper > .contacts-icon,
.client-page .client-summary .label-wrapper > div > .contacts-icon
{
    align-items: center;
    border: 1px solid #1890ff;
    border-radius: 2px;
    color: #1890ff;
    display: flex;
    height: 24px;
    padding: 4px;
    width: 24px;
}

.client-page .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}
.client-page .client-summary .label-wrapper > .request-wrapper {
    display: flex;
    align-items: center;
}
.client-page .client-summary .label-wrapper > .request-wrapper p {
   margin: 0 8px 0 0;
}

.client-page .client-summary .label-wrapper > .type-default {
    color: #000000;
}

.client-page .client-summary .template-label {
    background: #9254DE;
    right: 0;
    color: #fff;
    width: 93px;
    height: 28px;
    font-size: 16px;
    text-align: center;
}

.client-page .client-summary .client-label {
    color: #1890FF;
    cursor: pointer;
}

.client-page .client-summary .client-summary-right-column > div {
    margin-bottom: 16px;
}

.client-page .client-summary .client-summary-right-column .ant-select-arrow {
    top: 16px;
}

.client-page .client-summary .client-summary-right-column .ant-select:hover .ant-select-clear {
    top: 16px;
}

.client-page .client-summary .see-projects {
    display: none;
}

.client-page .client-summary .label-wrapper.requested:hover .see-projects {
    display: block;
}

.client-page .client-summary .label-wrapper.active:hover .see-projects {
    display: block;
}